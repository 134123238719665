<template>
  <div>
    <loadingspinner v-if="loading"></loadingspinner>
    <div :class="{hidden:loading}">
      <!-- Use class="hidden", not v-if. With v-if, the birthdatepicker is reset -->

      <div class="min-h-screen bg-white flex justify-center lg:justify-start lg:bg-transparent">
        <div class="flex flex-col lg:flex-row px-4 lg:px-0 max-w-xl lg:max-w-none w-full">

          <div
              class="flex flex-column justify-center bg-white lg:pl-6 lg:pr-6 lg:pb-6 md:pt-4 lg:pb-16 lg:w-1/3 order-2 lg:order-1 pb-20">

            <div class="my-6 hidden lg:block">
              <ModalLogo></ModalLogo>
            </div>
            <h2 class="flex-wrap mb-8 text-center lg:text-left hidden lg:flex">
              <div class="mr-2">
                {{ $t('login.welcome') }}
              </div>
              <div>
                {{ client.name }}
              </div>
            </h2>

            <div v-if="!this.user || this.localCartItem.itemType !== 'appointment'">
              <!-- User is not logged in -->
              <div class="input-max-w-full" v-if="loginMode === 'login'">

                <div v-if="!client.settings.signup_closed_with_token">
                  <div class="mb-4 flex items-center flex-wrap">
                    <p class="mb-2 mr-2">
                      {{ $t('login.forSignupCreateProfile') }}.
                    </p>
                    <button class="btn btn-primary mb-2 font-bold whitespace-no-wrap"
                            @click="loginMode = 'signup'">
                      {{ $t('login.createProfile') }}
                    </button>
                  </div>

                  <p class="mb-4">
                    {{ $t('login.alreadyHaveProfile') }}
                  </p>
                </div>

                <form novalidate @submit.prevent="submitForm">
                  <InputField
                      :label="$t('login.emailLabel')"
                      :class="getLoginValidationClass('email')"
                      type="email"
                      autocomplete="email"
                      v-model="loginForm.email"
                  >
                    <template v-slot:errors>
                      <span class="error" v-if="!$v.loginForm.email.required">{{
                          $t('login.typeEmail')
                        }}</span>
                      <span class="error" v-else-if="!$v.loginForm.email.email">{{
                          $t('login.notValidEmail')
                        }}</span>
                    </template>
                  </InputField>

                  <InputField
                      :label="$t('login.passwordLabel')"
                      :inputLabel="$t('login.passwordLabel')"
                      :class="getLoginValidationClass('password')"
                      type="password"
                      v-model="loginForm.password"
                  >
                    <template v-slot:errors>
                      <span class="error" v-if="!$v.loginForm.password.required">{{
                          $t('login.passMustBeEntered')
                        }}</span>
                    </template>
                  </InputField>

                  <div class="login-failed" v-if="loginFailed">{{
                      $t('login.wrongEmailOrPassword')
                    }}
                  </div>
                </form>

              </div> <!-- login -->

              <div class="input-max-w-full" v-if="loginMode === 'signup'">

                <InputField
                    :class="getSignupValidationClass('first_name')"
                    :label="$t('login.firstNameLabel')"
                    type="text"
                    v-model="signupForm.first_name"
                    :required="true"
                    autocomplete="given-name"
                >
                  <template v-slot:errors>
                    <span class="error" v-if="!$v.signupForm.first_name.required">{{
                        $t('login.firstNameMust')
                      }}</span>
                  </template>
                </InputField>

                <InputField
                    :class="getSignupValidationClass('last_name')"
                    :label="$t('login.lastNameLabel')"
                    type="text"
                    v-model="signupForm.last_name"
                    :required="true"
                    autocomplete="family-name"
                >
                  <template v-slot:errors>
                    <span class="error" v-if="!$v.signupForm.last_name.required">{{
                        $t('login.lastNameMust')
                      }}</span>
                  </template>
                </InputField>

                <InputField
                    :label="$t('login.emailLabel')"
                    :class="getSignupValidationClass('email')"
                    type="email"
                    :required="true"
                    autocomplete="email"
                    v-model="signupForm.email"
                >
                  <template v-slot:errors>
                    <span class="error"
                          v-if="!$v.signupForm.email.required">{{ $t('login.typeEmail') }}</span>
                    <span class="error" v-else-if="!$v.signupForm.email.email">{{
                        $t('login.notValidEmail')
                      }}</span>
                  </template>
                </InputField>

                <!--<InputField
                  :label="$t('login.birthdayLabel')"
                  :class="getSignupValidationClass('date_of_birth')"
                  :required="client.settings.signup_date_of_birth_field_required"
                  v-model="signupForm.date_of_birth"
                  type="date"
                  v-if="client.settings.signup_show_date_of_birth_field"
                >
                  <template v-slot:errors>
                    <span class="error" v-if="$v.signupForm.date_of_birth && !$v.signupForm.date_of_birth.required">{{ $t('login.birthdayMust') }}</span>
                  </template>
                </InputField>-->

                <BirthdaySelect
                    v-model="signupForm.date_of_birth"
                    :class="getSignupValidationClass('date_of_birth')"
                    v-if="client.settings.signup_show_date_of_birth_field"
                    :required="client.settings.signup_date_of_birth_field_required"
                >
                  <template v-slot:errors>
                    <span class="error mt-2"
                          v-if="$v.signupForm.date_of_birth && !$v.signupForm.date_of_birth.required">{{
                        $t('login.birthdayMust')
                      }}</span>
                  </template>
                </BirthdaySelect>

                <InputField
                    :label="$t('login.phoneLabel')"
                    :class="getSignupValidationClass('phone')"
                    type="tel"
                    pattern="^\+?[\d -]+$"
                    autocomplete="tel"
                    v-model="signupForm.phone"
                    v-if="client.settings.signup_show_phone_field"
                    :required="client.settings.signup_phone_field_required"
                >
                  <template v-slot:errors>
                  <span class="error" v-if="$v.signupForm.phone">
                    <span v-if="!$v.signupForm.phone.required">{{ $t('login.phoneMust') }}</span>
                  </span>
                  </template>
                </InputField>

                <InputField
                    v-if="client.settings.signup_show_address_fields"
                    :class="getSignupValidationClass('address_1')"
                    :label="$t('login.addressLabel')"
                    type="text"
                    v-model="signupForm.address_1"
                    :required="client.settings.signup_address_fields_required"
                    autocomplete="street-address"
                >
                  <template v-slot:errors>
                    <span class="error"
                          v-if="client.settings.signup_address_fields_required && !$v.signupForm.address_1.required">{{
                        $t('login.addressMust')
                      }}</span>
                  </template>
                </InputField>

                <InputField
                    v-if="client.settings.signup_show_address_fields"
                    :class="getSignupValidationClass('zip_code')"
                    :label="$t('login.zipLabel')"
                    type="text"
                    v-model="signupForm.zip_code"
                    :required="client.settings.signup_address_fields_required"
                    autocomplete="postal-code"
                >
                  <template v-slot:errors>
                    <span class="error"
                          v-if="client.settings.signup_address_fields_required && !$v.signupForm.zip_code.required">{{
                        $t('login.zipMust')
                      }}</span>
                  </template>
                </InputField>

                <InputField
                    v-if="client.settings.signup_show_address_fields"
                    :class="getSignupValidationClass('city')"
                    :label="$t('login.cityLabel')"
                    type="text"
                    v-model="signupForm.city"
                    :required="client.settings.signup_address_fields_required"
                    autocomplete="address-level2"
                >
                  <template v-slot:errors>
                    <span class="error"
                          v-if="client.settings.signup_address_fields_required && !$v.signupForm.city.required">{{
                        $t('login.cityMust')
                      }}</span>
                  </template>
                </InputField>

                <div v-if="client.settings.signup_show_address_fields" class="yogo-input mb-6">
                  <label>{{ $t('login.countryLabel') }}<span
                      v-if="client.settings.signup_address_fields_required">*</span></label>
                  <CountrySelect
                      v-model="signupForm.country"
                  />
                </div>

                <div v-if="showSignupBranchfield" class="yogo-input mb-6">
                  <label>{{ client.settings.customer_branch_field_label }}</label>
                  <select
                      v-model="signupForm.branchId"
                  >
                    <option v-if="!client.settings.signup_branch_field_required"
                            :value="0"></option>
                    <option
                        v-for="branch in client.branches" :value="branch.id"
                        :key="'branch' + branch.id"
                    >
                      {{ branch.name }}
                    </option>
                  </select>
                </div>

                <InputField
                    :label="$t('login.passwordLabel')"
                    :class="getSignupValidationClass('password')"
                    :required="true"
                    id="password"
                    type="password"
                    v-model="signupForm.password"
                    autocomplete="off"
                >
                  <template v-slot:errors>
                    <span class="error" v-if="!$v.signupForm.password.required">{{
                        $t('login.passwordMustBeCreated')
                      }}</span>
                    <span class="error" v-else-if="!$v.signupForm.password.passwordStrength">{{
                        $t('login.passwordDescription')
                      }}</span>
                  </template>
                </InputField>
              </div> <!-- signup -->


              <label class="checkbox mb-4"
                     v-if="loginMode == 'signup' && client.settings.signup_show_newsletter_checkbox">
                <input type="checkbox" v-model="signupForm.newsletter">
                <span>
                  {{ client.settings.signup_newsletter_checkbox_label }}
                </span>
              </label>

              <label
                  class="checkbox"
                  v-if="(localCartItem && localCartItem.itemType !== 'class' && localCartItem.itemType !== 'class_livestream') || loginMode === 'signup'"
              >
                <input type="checkbox" v-model="termsAccepted">
                <span class="leading-tight">
                  <accept-terms-text />
                </span>
              </label>

              <label
                  v-if="showRecurringTerms"
                  class="checkbox mt-4"
              >
                <input type="checkbox" v-model="membershipTermsAccepted">
                <span>
                  <accept-membership-terms-text />
                </span>
              </label>

              <label
                  v-if="client.settings.customer_must_accept_the_privacy_policy"
                  class="checkbox mt-4"
              >
                <input type="checkbox" v-model="privacyPolicyAccepted">
                <span>
                  <accept-privacy-policy-text />
                </span>
              </label>

              <label
                  v-if="client.settings.customer_must_accept_the_consent_form"
                  class="checkbox mt-4"
              >
                <input type="checkbox" v-model="consentFormAccepted">
                <span>
                  <accept-consent-form-text/>
                </span>
              </label>

              <button class="btn btn-primary my-4 w-full" type="submit" @click="submitForm">
                {{ submitButtonText }}
              </button>

              <div class="flex space-between items-center">
                <div class="hidden-md-down">
                  <label class="checkbox mr-4">
                    <input type="checkbox" v-model="rememberMe">
                    <span class="leading-tight">{{ $t('login.rememberMeLabel') }}</span>
                  </label>
                </div>

                <div class="py-2">
                  <div v-if="loginMode == 'login'">
                    <router-link :to="{name: 'ResetPassword' }">
                      <button class="font-bold underline text-left">
                        {{ $t('login.forgotPass') }}
                      </button>
                    </router-link>
                  </div>
                  <div v-if="loginMode == 'signup'" class="font-bold underline">
                    <a href="#" @click.prevent="loginMode = 'login'">
                      {{ $t('login.backToLogin') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <md-dialog-alert
              :md-active.sync="showLoginFailed"
              :md-title="$t('login.loginFailed')"
              :md-content="$t('login.wrongEmailorCode')"
          >
          </md-dialog-alert>

          <div
              class="lg:px-4 lg:min-h-screen flex items-center justify-center flex-col lg:w-2/3 order-1t lg:order-2">

            <div class="my-6 lg:hidden">
              <ModalLogo></ModalLogo>
            </div>
            <h2 class="flex flex-wrap mb-8 justify-center lg:justify-left lg:hidden">
              <div class="mr-2">
                {{ $t('login.welcome') }}
              </div>
              <div>
                {{ client.name }}
              </div>
            </h2>

            <ul class="w-full lg:w-3/5">

              <li v-if="localCartItem">

                <ul
                    v-if="cartItemType === 'product'"
                    class="w-full"
                >
                  <li
                      v-for="(cartItem, idx) in localCartItem"
                      class="flex items-center justify-between mb-4"
                      :key="'cartItem_' + idx"
                  >
                    <div class="flex items-center">
                      <img
                          v-if="cartItem.product && cartItem.product.image"
                          style="width:60px;"
                          :src="Imgix(cartItem.product.image.filename, {w:80,h:80})"
                          alt=""
                      >
                      <span class="ml-2" v-if="cartItem.product">
                        {{ cartItem.item_count }} x {{
                          cartItem.product ? cartItem.product.name : ''
                        }}
                      </span>
                    </div>
                    <div>
                      <span>
                        {{
                          formatCurrency(cartItem.product ? cartItem.item_count * cartItem.product.price : 0)
                        }}
                      </span>
                    </div>
                  </li>

                </ul>

                <div class="flex justify-center flex-col items-center"
                     v-else-if="localCartItem.itemType =='appointment'">

                  <div class="text-center flex flex-col" v-if="this.user">
                    <h2 class="mb-4 font-bold">{{ $t('login.Hi') }} {{ this.user.first_name }}!</h2>
                    <ProfileImage class="flex self-center" :image="this.user.image" :size="90"/>
                  </div>

                  <h2 class="my-8 py-4 bold px-4 md:px-10 flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <div class="text-center">
                      <div class="text-base font-normal">{{ $t('login.YouAreAboutToBook') }}</div>
                      <h6>{{ appointmentType ? appointmentType.name : '' }}</h6>
                      <div class="text-base" v-if="teacher">
                        {{ $t('global.with') }}
                      </div>
                      <h6 class="text-base font-extrabold" v-if="teacher">
                        {{ `${teacher.first_name} ${teacher.last_name}` }}
                      </h6>
                      <div class="mb-4 text-base font-bold mt-2">
                        {{ $t('global.Date') }}: <span>{{ formatDate(localCartItem.date) }} </span>
                        <br>
                        {{ $t('global.Time') }}: <span>{{
                          localCartItem.start_time.substr(0, 5)
                        }} </span>
                      </div>
                    </div>
                  </h2>

                  <h3 class="text-center font-bold mb-4" v-if="user">
                    {{ $t('global.HowWouldYouLikeToPay') }}
                  </h3>
                  <h3 class="text-center font-bold" v-else>
                    {{ $t('global.PleaseLogInOrSignUpToBookAppointment') }}
                  </h3>

                  <!-- Customer's existing access items -->
                  <div class="flex justify-center flex-col items-center w-full md:w-9/12"
                       v-if="appointmentShowPaymentOptions">
                    <div class="flex flex-col w-full" v-if="userClassPasses.length > 0">
                      <label
                          class="block text-sm font-medium text-gray-700 w-full"
                          v-for="(class_pass, idx) in userClassPasses"
                          :key="'user_class_pass_' + idx"
                      >
                        <div class="flex items-center mb-2">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              :value="'class_pass_' + class_pass.id"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                              :style="{'accent-color': client.settings.theme_primary_color}"
                          >
                          <span class="flex space-between w-full border-b pb-1">
                              <span class="font-bold">
                                {{ class_pass.class_pass_type.name }}
                              </span>
                              {{ class_pass.classes_left + ' ' }} {{
                              $t('global.appointmentsLeft')
                            }}
                            </span>
                        </div>
                      </label>
                    </div>
                    <div v-if="userMemberships.length > 0">
                      <label
                          class="block text-sm font-medium text-gray-700 w-full"
                          v-for="(membership, idx) in userMemberships"
                          :key="'user_membership' + idx"
                      >
                        <div class="flex items-center">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              :value="'membership_' + membership.id"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                              :style="{'accent-color': client.settings.theme_primary_color}"
                          >
                          <span class="font-bold">
                            {{ membership.membership_type.name }}
                          </span>
                        </div>
                      </label>
                    </div>

                    <!-- The button that toggles visibility of payment options that are not customer's existing -->
                    <div
                        v-if="appointmentCustomerHasExistingAccess"
                    >
                      <button
                          @click="appointmentShowMorePaymentOptions = !appointmentShowMorePaymentOptions"
                          class="mt-2 flex self-center btn btn-secondary mb-4"
                      >
                        {{
                          appointmentShowMorePaymentOptions ? $t('global.LessOptions') : $t(
                              'global.MoreOptions')
                        }}
                      </button>
                    </div>

                    <!-- Payment options that are NOT customer's existing access items-->
                    <div
                        v-if="!appointmentCustomerHasExistingAccess || appointmentShowMorePaymentOptions"
                        class="flex flex-col items-start w-full"
                    >
                      <label
                          class="block text-sm font-medium text-gray-700 w-full"
                          v-if="appointmentType.direct_purchase_enabled"
                      >
                        <div class="flex items-center">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              value="directPurchase"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold pb-1"
                              :style="{'accent-color': client.settings.theme_primary_color}"
                          >
                          <span
                              class="w-full flex space-between border-b pb-1 align__items--center">
                            <span class="font-bold">{{ $t('global.Buy') }}</span>
                            <span class="text-right">
                              <span :class="{strikethrough: appointmentType.has_member_discount}">{{
                                  formatCurrency(appointmentType.price)
                                }}</span>
                              <span v-if="appointmentType.has_member_discount">
                                <br>
                                {{ formatCurrency(appointmentType.price_with_member_discount) }}
                              </span>
                            </span>

                          </span>
                        </div>

                      </label>
                      <br/><br/>
                      <label
                          class="block text-sm font-medium text-gray-700 w-full"
                          v-for="(class_pass_type, idx) in appointmentType.valid_class_pass_types"
                          :key="'class_pass_type' + idx"
                      >
                        <div class="flex items-center">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              :value="`class_pass_type_${class_pass_type.id}`"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                              :style="{'accent-color': client.settings.theme_primary_color}"
                          >
                          <span
                              class="w-full flex space-between border-b pb-1 align__items--center">
                            <span class="font-bold">
                              {{ class_pass_type.name }}
                            </span>
                            <span class="text-right">
                              <span :class="{strikethrough: class_pass_type.has_member_discount}">
                                {{ formatCurrency(class_pass_type.price) }}
                              </span>
                              <span v-if="class_pass_type.has_member_discount">
                                <br>
                                {{ formatCurrency(class_pass_type.price_with_member_discount) }}
                              </span>
                            </span>
                          </span>
                        </div>
                      </label>
                      <label
                          class="block text-sm font-medium text-gray-700 w-full"
                          v-for="(membership_type, idx) in appointmentType.valid_membership_types"
                          :key="'membership_type' + idx"
                      >
                        <div class="flex items-center">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              :value="`membership_type_${membership_type.id}`"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                              :style="{'accent-color': client.settings.theme_primary_color}"
                          >
                          <span class="font-bold">
                            {{ membership_type.name }}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="mb-8" v-else>
                  <img
                      v-if="cartItemImage"
                      class="w-full max-w-xl m-auto"
                      :src="Imgix(cartItemImage.filename, {w: 600, h:(cartItemImageDimensions === 'landscape' ? 400 : 600), fit:'crop'})"
                      alt="Cart item image"
                  >
                  <br v-if="cartItemImage">
                  <h1 class="text-center my-2">
                    {{ cartItemName }}
                  </h1>
                  <h3 class="text-center font-bold">
                    <span v-if="cartItemSubtitle" class="block mb-2">{{ cartItemSubtitle }}</span>
                    <span v-if="cartItemInfo" class="block">{{ cartItemInfo }}</span>
                  </h3>
                  <datepicker
                      v-model="membershipStartDate"
                      v-if="showMembershipStartDateSelector"
                      :label="$t('membership.MembershipStartsOnDate')"
                      :disabled-dates="membershipStartDateDisabledDates"
                      class="mt-8 flex flex-col items-center"
                      :borderNone="true"
                  />
                </div>

              </li>
            </ul>

            <div class="flex-col items-center text-center" v-if="cartItemType == 'event'">
              <span class="font-bold">
                {{ $t('global.Seats') }}:
              </span>
              <YogoNumberInput
                  v-if="client.settings.customer_can_buy_multiple_seats_for_event"
                  v-model="localCartItem.itemCount"
                  :min="1"
                  :max="currentEvent.seats - currentEvent.signup_count"
              ></YogoNumberInput>
            </div>

            <div class="flex-col items-center text-center"
                 v-if="cartItemType == 'class' && client.settings.customer_can_reserve_multiple_seats_for_class && requestedClass">
              <span class="font-bold">
                {{ $t('global.Seats') }}:
              </span>
              <YogoNumberInput
                  v-model="localCartItem.itemCount"
                  :min="1"
                  :max="requestedClass.seats - requestedClass.signup_count"
              ></YogoNumberInput>
            </div>

            <div v-if="linesAboveTotal" class="w-full lg:w-3/5">
              <div v-for="(line, idx) in linesAboveTotal" class="flex space-between w-full"
                   :key="idx">
                <span class="flex--50">{{ line.text }}</span>
                <span class="flex--50  text-right">{{ formatCurrency(line.amount) }}</span>
              </div>
            </div>
            <h2
                v-if="localCartItem && localCartItem.itemType !== 'class' && localCartItem.itemType !== 'class_livestream' && (
                    localCartItem.itemType !== 'appointment' || appointmentShowTotal)"
                class="my-8 py-4 bold w-full lg:w-3/5 flex space-between border-t border-black"
            >

              <span v-if="localCartItem.itemType === 'event_first_installment'">{{
                  $t('event.ForPaymentNow')
                }}:</span>
              <span v-else>{{ $t('global.Total') }}:</span>

              <span>{{
                  formatCurrency(cartItemPrice, client.settings.payment_show_dankort_logo)
                }} </span>

            </h2>

            <!-- Discount code input -->
            <div v-if="client.settings.checkout_show_discount_code_input">
              <form
                  action=""
                  @submit.stop="applyDiscountCode"
                  v-if="showDiscountCodeInput"
                  class="w-full md:w-auto"
              >

                <InputField
                    :label="$t('checkout.DiscountCodeSlashGiftCard')"
                    v-model="discountCodeInput"
                    class="text-center"
                >
                </InputField>

                <button class="btn btn-primary w-full mb-6" type="button"
                        @click.stop="applyDiscountCode">
                  {{ $t('checkout.applyDiscountCode') }}
                </button>
              </form>

              <div class="flex justify-between items-center flex-wrap w-full lg:w-3/5 mb-8"
                   v-if="appliedDiscountCode"
                   style="margin-top:20px;">
                <h3 class="font-bold mb-2">{{ $t('checkout.DiscountCodeSlashGiftCard') }}:
                  {{ appliedDiscountCode }}</h3>
                <button @click="removeAppliedDiscountCode" class="btn btn-small btn-primary mb-2">
                  {{ $t('global.Remove') }}
                </button>
              </div>
              <div class="flex self-start md:self-center mb-4"
                   v-if="showDiscountCodeInput || appliedDiscountCode">
              <span class="text-xs -mt-4 mb-4 font-bold text-gray-600 md:px-2">
                {{ $t('global.AmountDrawnOnPayment') }}
              </span>
              </div>
            </div>
            <!-- End discount code input -->

            <div v-if="appointmentShowBookButton">
              <button
                  @click="appointmentBook"
                  class="btn btn-primary my-8"
              >
                {{ $t('global.BookAppointment') }}
              </button>
            </div>

            <div v-if="appointmentShowPayButton" class="w-full lg:w-3/5">
              <label class="checkbox">
                <input type="checkbox" v-model="termsAccepted">
                <span class="leading-tight">
                  <accept-terms-text />
                </span>
              </label>

              <label class="checkbox mt-4">
                <input type="checkbox" v-model="privacyPolicyAccepted">
                <span class="leading-tight">
                  <accept-privacy-policy-text />
                </span>
              </label>

              <label class="checkbox mt-4">
                <input type="checkbox" v-model="consentFormAccepted">
                <span class="leading-tight">
                  <accept-consent-form-text />
                </span>
              </label>

              <button
                  @click="appointmentPay"
                  class="btn btn-primary my-8 w-full"
              >
                {{ $t('global.ContinueToPayment') }}
              </button>
            </div>


          </div>

        </div>

      </div>

      <md-dialog :md-active.sync="showAlreadySignedUpForEventDialog">
        <md-dialog-content>{{ $t('calendar.youAreAlreadySignedUp') }}.</md-dialog-content>
        <md-dialog-actions>
          <button class="btn btn-primary" @click="backToEventList">{{ $t('global.back') }}</button>
          <button class="btn btn-primary" @click="backToMyProfile">{{
              $t('global.showMyProfile')
            }}
          </button>
        </md-dialog-actions>
      </md-dialog>


      <forgot-to-accept-terms-alert :show.sync="showForgotToAcceptTermsDialog"/>
      <forgot-to-accept-membership-terms-alert :show.sync="showForgotToAcceptMembershipTermsDialog"/>
      <forgot-to-accept-privacy-policy-alert :show.sync="showForgotToAcceptPrivacyPolicyDialog"/>
      <forgot-to-accept-consent-form-alert :show.sync="showForgotToAcceptConsentFormDialog"/>

      <md-dialog :md-active.sync="showUserExists">
        <md-dialog-title>{{ $t('login.emailExcist') }}</md-dialog-title>
        <md-dialog-content>
          <p>{{ $t('login.emailExcistDesc') }} "{{ signupForm.email }}".</p>
          <p>{{ $t('global.please') }}
            <a href="#" @click="loginMode = 'login'; showUserExists = false">{{
                $t('login.signin')
              }}</a>.
          </p>
          <p>
            {{ $t('login.ifYouForgotYourPass') }}
            <router-link :to="{ name: 'ResetPassword' }" class="whitespace--nowrap">{{
                $t('login.resetItHere')
              }}
            </router-link>
          </p>

        </md-dialog-content>
        <md-dialog-actions>
          <button class="btn btn-primary" @click="showUserExists = false">OK</button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active.sync="showMembershipExistsDialog" v-if="existingMembership">
        <md-dialog-title>{{ $t('login.youAlreadyHaveMembership') }}
          {{ existingMembership.membership_type.name }}.
        </md-dialog-title>
        <md-dialog-actions>
          <button class="btn btn-primary" @click="cancelMembershipExistsDialog">{{
              $t('global.cancelButton')
            }}
          </button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active.sync="showClassSignupSuccessDialog" v-if="requestedClass">
        <md-dialog-title>{{ $t('login.thanksForYourSubmit') }}</md-dialog-title>
        <md-dialog-content>
          {{ $t('calendar.youAreNowSignedUpFor') }}<br>
          {{ requestedClass.class_type.name }}<br>
          <span v-if="requestedClass.subtitle">{{ requestedClass.subtitle }}<br></span>
          {{
            formatDate(requestedClass.date)
          }}, {{
            requestedClass.start_time
          }}
        </md-dialog-content>
        <md-dialog-actions>
          <button class="btn btn-primary" @click="backToCalendar">{{ $t('global.back') }}</button>
          <button class="btn btn-primary" @click="$router.push({name:'MyProfile'})">{{
              $t('global.showMyProfile')
            }}
          </button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog
          :md-active.sync="showUserHasNoAccessToClassDialog"
          v-if="requestedClass"
      >
        <md-dialog-title v-if="loginMode === 'login'">{{
            $t('login.PleaseSelectAMembershipOrClassPass')
          }}
        </md-dialog-title>
        <md-dialog-title v-if="loginMode === 'signup'">{{
            $t('login.profileCreated')
          }}
        </md-dialog-title>
        <md-dialog-content>
          <p class="font-semibold mb-4">
            <span v-if="localCartItem.itemCount === 1">{{ $t('login.youNeedValidMembershipDesc') }} {{
                requestedClass.class_type.name
              }} {{
                formatDate(requestedClass.date)
              }}, {{
                requestedClass.start_time.substring(0, 5)
              }}</span>
            <span v-else>
              {{ $t('login.youNeedValidMembershipDescMultipleSeatsPart1') }}
              <span class="underline">{{
                  $tc('global.NSeats',
                      localCartItem.itemCount,
                      { n: localCartItem.itemCount })
                }}</span>
              {{ $t('login.youNeedValidMembershipDescMultipleSeatsPart2') }}
              {{ requestedClass.class_type.name }}
              {{ formatDate(requestedClass.date) }},
              {{ requestedClass.start_time.substring(0, 5) }}
            </span>
          </p>

          <!--<p class="font-semibold mb-4" v-if="localCartItem.itemCount > 1">
            {{ $tc('global.NSeats', localCartItem.itemCount, {n: localCartItem.itemCount}) }}
          </p>-->

          <div
              class="block text-sm font-medium text-gray-700 w-full pointer"
              v-for="(membership_type, idx) in classValidMembershipTypes"
              :key="'membership_type' + idx"
          >
            <label
                v-for="(payment_option, idx) in membership_type.payment_options"
                :key="'payment_option_' + idx"
            >
              <div class="flex items-center pt-1">
                <input
                    type="radio"
                    v-model="selectedClassPaymentOption"
                    :value="`membership_type-${membership_type.id}-${payment_option.id}`"
                    class="focus:ring-black pointer h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                    :style="{'accent-color': client.settings.theme_primary_color}"
                >
                <span class="w-full flex space-between border-b pb-1 flex-wrap pointer">
                  <span class="font-bold">
                    {{ membership_type.name }} ({{ payment_option.nameThatUserSees }})
                  </span>
                  <span> {{ payment_option.priceTextThatUserSees }} </span>
                </span>
              </div>
            </label>
          </div>
          <label
              class="block text-sm font-medium text-gray-700 w-full"
              v-for="(class_pass_type, idx) in classValidClassPassTypes"
              :key="'class_pass_type' + idx"
          >
            <div class="flex items-center pt-1">
              <input
                  type="radio"
                  v-model="selectedClassPaymentOption"
                  :value="`class_pass_type-${class_pass_type.id}`"
                  class="focus:ring-black pointer h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                  :style="{'accent-color': client.settings.theme_primary_color}"
              >
              <div
                  class="w-full flex space-between border-b pb-1 flex-wrap pointer align__items--center">
                <span class="font-bold">
                  {{ class_pass_type.name }}
                  <span
                      v-if="class_pass_type.pass_type === 'fixed_count' && class_pass_type.number_of_classes < localCartItem.itemCount">
                    ({{
                      purchaseAccessMultipleClassPassText(class_pass_type,
                          localCartItem.itemCount)
                    }})
                  </span>
                </span>
                <div class="text-right">
                  <span :class="{strikethrough: class_pass_type.has_member_discount}">
                    <span
                        v-if="class_pass_type.pass_type === 'fixed_count' && class_pass_type.number_of_classes < localCartItem.itemCount">
                      {{
                        formatCurrency(class_pass_type.price * Math.ceil(localCartItem.itemCount / class_pass_type.number_of_classes))
                      }}
                    </span>
                    <span v-else>{{ formatCurrency(class_pass_type.price) }}</span>
                  </span>
                  <span v-if="class_pass_type.has_member_discount">
                    <br>
                    <span class="bold">
                      {{ $t('global.MemberPrice') }}
                      <span
                          v-if="class_pass_type.pass_type === 'fixed_count' && class_pass_type.number_of_classes < localCartItem.itemCount">
                        {{
                          formatCurrency(class_pass_type.price_with_member_discount * Math.ceil(
                              localCartItem.itemCount / class_pass_type.number_of_classes))
                        }}
                      </span>
                      <span v-else>{{
                          formatCurrency(class_pass_type.price_with_member_discount)
                        }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </label>


          <!--<label class="checkbox mt-4">
            <input type="checkbox" v-model="termsAccepted">
            <span class="leading-tight">
              {{ $t('checkout.iHaveReadTheTerms') }}
              <a class="underline" @click.prevent.stop="showTermsDialog=true">
                {{ $t('checkout.termsAndConditions') }}
              </a>
            </span>
          </label>-->
        </md-dialog-content>
        <md-dialog-actions class="flex flex-wrap">
          <div class="w-full mt-2 mr-2 md:w-auto md:mr-2">
            <button class="btn w-full" @click="dismissUserHasNoAccessToClassDialog">{{
                $t('global.Dismiss')
              }}
            </button>
          </div>
          <div class="w-full mt-2 ml-2 md:w-auto md:mr-2">
            <button
                class="btn btn-primary w-full"
                :disabled="!selectedClassPaymentOption"
                @click="classPayAndSignup()"
            >
              {{ $t('global.ContinueToPayment') }}
            </button>
          </div>
        </md-dialog-actions>
      </md-dialog>


      <md-dialog
          :md-active.sync="showEventSignupSuccessDialog"
          v-if="requestedEvent"
          @md-closed="closeOwnPopup"
          @md-click-outside="closeOwnPopup"
      >
        <md-dialog-title v-if="loginMode === 'login'">{{
            $t('login.thanksForYourSubmit')
          }}
        </md-dialog-title>
        <md-dialog-title v-if="loginMode === 'signup'">{{
            $t('login.yourProfileIsCreatedAndSubmitted')
          }}
        </md-dialog-title>
        <md-dialog-content>
          {{ $t('calendar.youAreNowSignedUpFor') }} {{ requestedEvent.name }}
        </md-dialog-content>
        <md-dialog-actions>
          <button class="btn btn-primary" @click="closeOwnPopup">{{ $t('global.back') }}</button>
          <button class="btn btn-primary" @click="$router.push({name: 'MyProfile'})">{{
              $t('global.showMyProfile')
            }}
          </button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog
          :md-active.sync="showAlertDialog"
          @md-closed="alertDialogClosed()"
      >
        <md-dialog-content>
          <nl2br tag="div" :text="alertDialogText"></nl2br>
        </md-dialog-content>
        <md-dialog-actions>
          <button class="btn" @click="showAlertDialog = false">{{ $t('global.Ok') }}</button>
        </md-dialog-actions>
      </md-dialog>

      <ClassSignup
          :user="user"
          :showErrorsToUser="false"
          @classSignupSuccess="classSignupSuccess"
          @classSignupFailure="classSignupFailure"
          ref="classSignupComponent"
      >
      </ClassSignup>

    </div>
  </div>
</template>

<script>
import qs from 'qs';
import { validationMixin } from 'vuelidate';
import YogoApi from '../gateways/YogoApi';
import ClientSite from '../gateways/ClientSite';
import { mapGetters } from 'vuex';
import Loadingspinner from './LoadingSpinner';
import { email, required } from 'vuelidate/lib/validators';

import _pick from 'lodash/pick';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _padEnd from 'lodash/padEnd';
import _sortBy from 'lodash/sortBy';

import ClassSignup from '@/components/ClassSignup.vue';

import Imgix from '@/services/Imgix';
import ModalLogo from './ModalLogo';
import InputField from './InputField.vue';
import BirthdaySelect from './BirthdaySelect.vue';
import YogoNumberInput from './ui/YogoNumberInput.vue';
import ReepayPaymentMixin from './ReepayPaymentMixin';

import StripePaymentMixin from '@/components/StripePaymentMixin';
import currencyFormatters from '@/mixins/currencyFormatters';
import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import sleep from '@/mixins/sleep';
import ProfileImage from '@/components/ProfileImage';
import { DateTime } from 'luxon';

import Datepicker from '@/components/Datepicker';
import CountrySelect from '@/components/CountrySelect';
import AcceptTermsText from '@/components/fragments/AcceptTermsText.vue';
import AcceptMembershipTermsText from '@/components/fragments/AcceptMembershipTermsText.vue';
import AcceptPrivacyPolicyText from '@/components/fragments/AcceptPrivacyPolicyText.vue';
import AcceptConsentFormText from '@/components/fragments/AcceptConsentFormText.vue';
import ForgotToAcceptMembershipTermsAlert
  from '@/components/fragments/ForgotToAcceptMembershipTermsAlert.vue';
import ForgotToAcceptTermsAlert from '@/components/fragments/ForgotToAcceptTermsAlert.vue';
import ForgotToAcceptConsentFormAlert
  from '@/components/fragments/ForgotToAcceptConsentFormAlert.vue';
import ForgotToAcceptPrivacyPolicyAlert
  from '@/components/fragments/ForgotToAcceptPrivacyPolicyAlert.vue';

export default {
  mixins: [
    validationMixin,
    Imgix,
    ReepayPaymentMixin,
    StripePaymentMixin,
    currencyFormatters,
    dateTimeFunctions,
    sleep,
  ],
  data() {
    return {
      userIsLoggedIn: false,

      email: '',
      password: '',
      rememberMe: false,

      loginFailed: false,
      loading: false,
      loginForm: {
        email: '',
        password: '',
      },
      signupForm: {
        country: 'DK',
        password: '',
        branchId: null,
        date_of_birth: null,
        newsletter: false,
      },
      showMembershipExistsDialog: false,
      existingMembership: null,
      showLoginFailed: false,

      currentEvent: {},
      cartItemImage: null,
      cartItemImageDimensions: '',
      cartItemName: '',
      cartItemSubtitle: '',
      cartItemInfo: '',
      cartItemPrice: 0,
      linesAboveTotal: null,
      showMembershipStartDateSelector: false,
      membershipStartDate: this.client
          ? DateTime.now().setZone(this.client.timezone).toISODate()
          : DateTime.now().toISODate(),
      membershipStartDateDisabledDates: null,


      loginMode: qs.parse(document.location.search.substr(1)).signupMode ? 'signup' : 'login',
      localCartItem: null, // This can be an array of products, but not of other item types
      cartItemType: '',
      showAlreadySignedUpForEventDialog: false,

      termsAccepted: false,
      showForgotToAcceptTermsDialog: false,

      membershipTermsAccepted: false,
      showForgotToAcceptMembershipTermsDialog: false,

      privacyPolicyAccepted: false,
      showForgotToAcceptPrivacyPolicyDialog: false,

      consentFormAccepted: false,
      showForgotToAcceptConsentFormDialog: false,

      showUserExists: false,

      requestedClass: null,

      userMemberships: [],
      userClassPasses: [],
      userClassSignups: [],

      classSignupSuccessDialogAddToCalendarLink: '',
      showClassSignupSuccessDialog: false,

      showUserHasNoAccessToClassDialog: false,
      additionalSeatsNeededForClass: 0,
      classValidMembershipTypes: [],
      classValidClassPassTypes: [],
      selectedClassPaymentOption: '',

      requestedEvent: null,
      showEventSignupSuccessDialog: false,

      showAlertDialog: false,
      alertDialogText: '',
      alertDialogConfirmText: 'Ok',
      alertDialogClosedAction: '',

      discountCodeInput: '',
      appliedDiscountCode: '',

      appointmentType: null,
      teacher: null,
      appointmentShowPaymentOptions: false,
      selectedAppointmentPaymentOption: '',

      appointmentShowMorePaymentOptions: false,
    };
  },
  components: {
    ForgotToAcceptTermsAlert,
    ForgotToAcceptMembershipTermsAlert,
    ForgotToAcceptPrivacyPolicyAlert,
    ForgotToAcceptConsentFormAlert,
    AcceptConsentFormText,
    AcceptPrivacyPolicyText,
    AcceptMembershipTermsText,
    AcceptTermsText,
    CountrySelect,
    ProfileImage,
    ModalLogo,
    Loadingspinner,
    ClassSignup,
    BirthdaySelect,
    InputField,
    YogoNumberInput,
    Datepicker,
  },
  computed: {
    ...mapGetters([
      'client',
      'clientLogoFilename',
      'requestedRoute',
      'user',
      'stateReady',
    ]),
    showDiscountCodeInput() {
      return !this.appliedDiscountCode && this.cartItemRequiresPayment;
    },
    cartItemRequiresPayment() {
      if (_includes(['event', 'event_first_installment', 'class_pass_type', 'class_series_type', 'class_series_type_first_installment', 'membership_type', 'product'],
          this.cartItemType)) {
        return true;
      }
      if (this.cartItemType === 'appointment') {
        return this.appointmentShowPayButton;
      }
      return false;
    },
    submitButtonText() {
      if (this.loginMode === 'login') {
        if (this.localCartItem.itemType === 'appointment') {
          return this.$t('loginWithCart.LogIn');
        }
        if (this.cartItemType === 'class') {
          return this.$t('loginWithCart.LogInAndSignUp');
        } else if (this.cartItemType === 'class_livestream') {
          return this.$t('loginWithCart.LogInAndSignUpForLivestream');
        } else {
          return this.$t('loginWithCart.LogInAndPay');
        }

      } else {

        if (this.localCartItem.itemType === 'appointment') {
          return this.$t('loginWithCart.CreateProfile');
        }
        if (this.cartItemType === 'class') {
          return this.$t('loginWithCart.CreateProfileAndSignUp');
        } else if (this.cartItemType === 'class_livestream') {
          return this.$t('loginWithCart.CreateProfileAndSignUpForLivestream');
        } else {
          return this.$t('loginWithCart.CreateProfileAndPay');
        }
      }

    },
    showRecurringTerms() {
      return this.localCartItem.itemType === 'membership_type'
          && this.client.settings.show_recurring_terms;
    },
    appointmentCustomerHasExistingAccess() {
      return this.userClassPasses.length || this.userMemberships.length;
    },
    appointmentShowTotal() {
      return this.appointmentShowPayButton;
    },
    appointmentShowBookButton() {
      return this.selectedAppointmentPaymentOption.match(/^class_pass_\d+$/)
          || this.selectedAppointmentPaymentOption.match(/^membership_\d+$/);
    },
    appointmentShowPayButton() {
      return this.selectedAppointmentPaymentOption.match(/^class_pass_type_\d+$/)
          || this.selectedAppointmentPaymentOption.match(/^membership_type_\d+$/)
          || this.selectedAppointmentPaymentOption === 'directPurchase';
    },
    showSignupBranchfield() {
      return this.client.branches.length && this.client.settings.signup_show_branch_field;
    },
  },

  async created() {

    // It client is loaded, set the membership start date disabled dates
    if (this.stateReady) {
      this.setMembershipStartDateDisabledDates();
    }

    // Init user signup branch selection
    if (this.loginMode === 'signup' && this.showSignupBranchfield) {
      this.signupForm.branchId = this.client.settings.signup_branch_field_required
          ? this.client.branches[0].id
          : 0;
    }

    // See if there is a product in route params
    const urlCartItemJson = this.$route.params.cartItemJson;
    if (urlCartItemJson) {
      this.localCartItem = JSON.parse(urlCartItemJson);
    } else if (this.$route.query.itemType) {
      this.localCartItem = _pick(this.$route.query, [
        'itemType',
        'itemId',
        'itemCount',
        'date',
        'start_time',
        'paymentOption',
        'appointment_type_id',
        'teacher_id',
        'branch_id',
      ]);
    } else {
      // Otherwise check query string
      const queryString = window.location.search.substr(1);
      this.localCartItem = qs.parse(queryString);
      if (!this.localCartItem) {
        return;
      }
    }

    // See if there is an item count in route params
    if (this.localCartItem.itemCount) {
      this.localCartItem.itemCount = parseInt(this.localCartItem.itemCount);
    } else {
      this.$set(this.localCartItem, 'itemCount', 1);
    }

    // If this is the first installment of an event, force item count to 1
    if (this.localCartItem.itemType === 'event_first_installment') {
      this.$set(this.localCartItem, 'itemCount', 1);
    }

    // Currently only products should be an array. Other types of items should be an object.
    if (_isArray(this.localCartItem)) {
      this.cartItemType = 'product';
    } else {
      this.cartItemType = this.localCartItem.itemType;
      if (this.cartItemType === 'product') {
        this.localCartItem = [{
          item_type: 'product',
          item_id: this.localCartItem.itemId,
          item_count: 1,
        }];
      }
      if (!this.localCartItem.itemCount) {
        this.localCartItem.itemCount = 1;
      }
    }

    // The api might return an appointment start_time without seconds, so we make sure it has seconds
    if (this.localCartItem.itemType === 'appointment') {
      this.localCartItem.start_time = _padEnd(this.localCartItem.start_time, 8, ':00');
    }

    if (this.stateReady) {
      this.initCartItem();
    }
  },

  mounted() {
    // Clear gift card details. They might be lingering after a cancelled payment.
    localStorage.removeItem('yogoFrontend_giftCardDetails');
  },

  methods: {
    async initCartItem() {
      this.signupForm.country = this.client.country;
      switch (this.cartItemType) {
        case 'event' :
        case 'event_first_installment': {
          const eventQueryParams = {
            id: this.localCartItem.itemId,
            populate: [
              'image',
              'event_group',
              'event_group.image',
              'signup_count',
              'signups',
              'current_required_paid_amount',
            ],
          };
          if (this.user) {
            eventQueryParams.populate.push('user_is_signed_up_for_event');
          }
          const eventQueryString = qs.stringify(eventQueryParams);
          const [event] = await YogoApi.get(`/events?${eventQueryString}`);
          if (!event) {
            alert('Invalid link');
            ClientSite.closeOwnPopup();
            return;
          }
          if (event.archived) {
            alert(this.$t('global.EventIsArchived'));
            ClientSite.closeOwnPopup();
            return;
          }
          if (parseInt(event.client_id || event.client) !== parseInt(this.client.id)) {
            alert('Invalid event ID!');
            ClientSite.closeOwnPopup();
            return;
          }

          if (this.user) {
            if (
                _find(event.signups, { user: this.user.id })
                && !this.client.settings.customer_can_buy_multiple_seats_for_event
            ) {
              alert(this.$t('global.YouAreAlreadySignedUpForTheEvent'));
              ClientSite.closeOwnPopup();
              return;
            }
            await this.addToCart({
              item_type: this.cartItemType,
              item_id: this.localCartItem.itemId,
              user: this.user.id,
              item_count: this.localCartItem.itemCount,
            });
            return;
          }
          this.currentEvent = event;
          // this.cartItemName = event.name;

          if (this.cartItemType === 'event_first_installment') {
            this.cartItemName = `${event.name}`;
            this.cartItemPrice = this.localCartItem.itemCount * event.current_required_paid_amount;
            const totalPriceFormatted = this.formatCurrency(event.price);
            this.cartItemName += ` - ${this.$t('event.FirstInstallmentSlashDeposit')}.`;
            this.cartItemSubtitle = ` ${this.$t('event.EventTotalPrice')}: ${totalPriceFormatted}`;
          } else {
            this.cartItemName = `${this.localCartItem.itemCount} x ${event.name}`;
            this.cartItemPrice = this.localCartItem.itemCount * event.price;
          }

          if (event.image) {
            this.cartItemImage = event.image;
            this.cartItemImageDimensions = 'landscape';
          } else {
            this.cartItemImage = null;
            this.cartItemImageDimensions = 'square';
          }

          setTimeout(
              () => {
                // Place these two checks after setting cartItemTitle, etc., so the customer has
                //assurance that they are trying to sign up for the correct event
                if (event.signup_count >= event.seats) {
                  alert(this.$t('global.EventIsFullyBooked'));
                  ClientSite.closeOwnPopup();
                  return;
                }
                console.log(event.start_date,
                    DateTime.now().setZone(this.client.timezone).toISODate());
                if (event.start_date < DateTime.now().setZone(this.client.timezone).toISODate()) {
                  alert(this.$t('global.EventHasStarted'));
                  ClientSite.closeOwnPopup();
                  return;
                }
              },
              50,
          );

          break;
        }
        case 'class_pass_type' : {
          const classPassType = await YogoApi.get('/class-pass-types/' + this.localCartItem.itemId + '?populate[]=image');
          if (parseInt(classPassType.client_id || classPassType.client) !== parseInt(this.client.id)) {
            alert('Invalid link!');
            ClientSite.closeOwnPopup();
            return;
          }
          if (classPassType.archived) {
            alert(this.$t('checkout.ThisProductIsNoLongerAvailable'));
            ClientSite.closeOwnPopup();
          }
          this.cartItemName = classPassType.name;
          this.cartItemPrice = classPassType.price;
          this.cartItemImage = null;
          this.cartItemImageDimensions = 'square';
          break;
        }
        case 'class_series_type' :
        case 'class_series_type_first_installment': {
          const queryString = qs.stringify({
            populate: [
              'image',
              'is_available_for_purchase',
              'current_price',
              'has_started',
              'number_of_remaining_classes_and_livestream_classes',
              'current_required_paid_amount',
            ],
          });
          const classSeriesType = await YogoApi.get(`/class-series-types/${this.localCartItem.itemId}?${queryString}`);
          if (parseInt(classSeriesType.client_id || classSeriesType.client) !== parseInt(this.client.id)) {
            alert('Invalid link!');
            ClientSite.closeOwnPopup();
            return;
          }
          if (classSeriesType.archived || !classSeriesType.is_available_for_purchase) {
            alert(this.$t('checkout.ThisProductIsNoLongerAvailable'));
            ClientSite.closeOwnPopup();
          }

          if (this.cartItemType === 'class_series_type_first_installment') {
            this.cartItemName = classSeriesType.name;
            this.cartItemPrice = this.localCartItem.itemCount * classSeriesType.current_required_paid_amount;
            const totalPriceFormatted = this.formatCurrency(classSeriesType.price);
            this.cartItemName += ` - ${this.$t('event.FirstInstallmentSlashDeposit')}.`;
            this.cartItemSubtitle = ` ${this.$t('event.EventTotalPrice')}: ${totalPriceFormatted}`;
          } else {
            this.cartItemName = classSeriesType.name;
            if (classSeriesType.has_started) {
              this.cartItemSubtitle = this.$t(
                  'global.ReducedPriceNClassesRemaining',
                  { n: classSeriesType.number_of_remaining_classes_and_livestream_classes },
              );
            }
            this.cartItemPrice = classSeriesType.current_price;
          }

          this.cartItemImage = null;
          this.cartItemImageDimensions = 'square';
          break;
        }
        case 'membership_type' : {
          const [membershipType] = await YogoApi.get('/membership-types' +
              '?id=' + this.localCartItem.itemId +
              '&populate[]=payment_options' +
              '&populate[]=payment_options.nameThatUserSees' +
              '&populate[]=payment_options.priceTextThatUserSees' +
              '&populate[]=image' +
              '&populate[]=active_campaign',
          );
          if (!membershipType || parseInt(membershipType.client_id || membershipType.client) !== parseInt(
              this.client.id)) {
            alert('Invalid link!');
            ClientSite.closeOwnPopup();
            return;
          }
          if (!membershipType) {
            alert(this.$t('checkout.ThisProductIsNoLongerAvailable'));
            ClientSite.closeOwnPopup();
          }
          let paymentOption = this.localCartItem.paymentOption
              ? _find(
                  membershipType.payment_options,
                  { id: parseInt(this.localCartItem.paymentOption), for_sale: true },
              )
              : null;
          if (!paymentOption) {
            paymentOption = _find(
                membershipType.payment_options,
                {
                  number_of_months_payment_covers: 1,
                  for_sale: true,
                },
            );
          }
          if (!paymentOption) {
            alert(this.$t('checkout.ThisProductIsNoLongerAvailable'));
            ClientSite.closeOwnPopup();
          }

          const doApplyCampaign = parseInt(paymentOption.number_of_months_payment_covers) === 1 && membershipType.active_campaign;
          this.cartItemImage = null;
          this.cartItemImageDimensions = 'square';

          this.cartItemName = membershipType.name;
          this.cartItemPrice = doApplyCampaign ? membershipType.active_campaign.reduced_price : paymentOption.payment_amount;
          this.cartItemSubtitle = ' (' + paymentOption.name + ')' + (doApplyCampaign ? (' - ' + membershipType.active_campaign.name) : '');

          let startDateIso = DateTime.now().setZone(this.client.timezone).toISODate();
          if (this.membershipStartDate > startDateIso) {
            startDateIso = this.membershipStartDate;
          }
          const startDateLuxon = DateTime.fromISO(startDateIso, { zone: this.client.timezone });
          const ratioOfRemainingDaysAndFullMonthDays = (startDateLuxon.daysInMonth - startDateLuxon.day + 1) / startDateLuxon.daysInMonth;

          const applyPartialPayment = this.client.settings.memberships_always_renew_at_1st_of_month
              && ratioOfRemainingDaysAndFullMonthDays < 1
              && (
                  !membershipType.active_campaign
                  || paymentOption.number_of_months_payment_covers > 1
              );

          if (applyPartialPayment) {
            if (paymentOption.number_of_months_payment_covers === 1) {
              this.cartItemPrice = this.cartItemPrice * ratioOfRemainingDaysAndFullMonthDays;
              this.cartItemSubtitle += ' - ' + this.$t(
                  'membership.PaymentForRestOfMonthMembershipWillRenewOnDate',
                  {
                    date: this.formatDate(
                        startDateLuxon.plus({ months: 1 }).startOf('month').toISODate(),
                    ),
                  },
              );
            } else {
              const monthlyPrice = this.cartItemPrice / paymentOption.number_of_months_payment_covers;
              this.cartItemPrice = monthlyPrice * (ratioOfRemainingDaysAndFullMonthDays + paymentOption.number_of_months_payment_covers - 1);
              this.cartItemSubtitle += ' - ' + this.$t(
                  'membership.PaymentForRestOfMonthPlusNMonthsMembershipWillRenewOnDate',
                  {
                    months: paymentOption.number_of_months_payment_covers - 1,
                    date: this.formatDate(
                        startDateLuxon.plus({ months: paymentOption.number_of_months_payment_covers })
                            .startOf('month').toISODate(),
                    ),
                  },
              );
            }
          }

          if (membershipType.registration_fee) {
            this.linesAboveTotal = [
              {
                text: this.$t('global.Membership'),
                amount: this.cartItemPrice,
              },
              {
                text: this.client.settings.membership_registration_fee_name,
                amount: membershipType.registration_fee,
              },
            ];
            this.cartItemPrice += membershipType.registration_fee;
          }

          if (
              this.client.settings.customer_can_choose_membership_start_date
              && !(
                  membershipType.active_campaign
                  && membershipType.active_campaign.campaign_type === 'until_date'
              )
          ) {
            this.showMembershipStartDateSelector = true;
          }

          break;
        }
        case 'class' :
        case 'class_livestream': {
          const classQueryString = qs.stringify({
            id: this.localCartItem.itemId,
            populate: [
              'class_type',
              'class_type.image',
              'class_type.class_pass_types',
              'class_type.membership_types',
              'class_type.membership_types.payment_options',
              'class_type.class_pass_types_livestream',
              'class_type.membership_types_livestream',
              'class_type.membership_types_livestream.payment_options',
            ],
          });

          ({ classes: [this.requestedClass] } = await YogoApi.get(`/classes?${classQueryString}`));
          if (parseInt(this.requestedClass.client_id || this.requestedClass.client) !== parseInt(
              this.client.id)) {
            alert('Invalid link!');
            ClientSite.closeOwnPopup();
            return;
          }

          this.cartItemName = (this.cartItemType === 'class_livestream' ? `${this.$t(
              'global.Livestream')}: ` : '') + this.requestedClass.class_type.name;
          this.cartItemSubtitle = this.requestedClass.subtitle;
          this.cartItemInfo += this.formatDate(
              `${this.requestedClass.date} ${this.requestedClass.start_time}`,
              { weekday: true, time: true },
          );
          this.cartItemImage = this.requestedClass.class_type.image;
          this.cartItemImageDimensions = 'landscape';
          break;
        }

        case 'appointment': {
          if (!this.checkAppointmentAvailability()) return;

          const query = qs.stringify({
            populate: [
              'appointment_available_intervals',
              'valid_class_pass_types',
              'valid_class_pass_types.price_with_member_discount',
              'valid_membership_types',
              'has_member_discount',
              'price_with_member_discount',
            ],
          });

          this.appointmentType = await YogoApi.get(`/appointment-types/${this.localCartItem.appointment_type_id}?${query}`);
          if (this.localCartItem.teacher_id) {
            this.teacher = await YogoApi.get(`/users?id=${this.localCartItem.teacher_id}&teacher=1`);
            if (_isArray(this.teacher)) {
              this.teacher = this.teacher[0];
            }
          }

          // We need to wait and see if the user is logged in. We
          while (!this.stateReady) {
            await this.sleep(5);
          }

          // If the user is logged in, get the user's valid memberships/class passes
          if (this.user) {
            if (this.appointmentType.customer_point_of_payment === 'outsideYogo') {
              this.selectedAppointmentPaymentOption = 'outsideYogo';
              this.appointmentBook();
              return;
            }
            await this.loadUserAccessItemsForAppointment();
          }

          break;
        }

        case 'product': {
          for (let i = 0; i < this.localCartItem.length; i++) {
            const cartItem = this.localCartItem[i];
            cartItem.product = await YogoApi.get(`/products/${cartItem.item_id}?populate[]=image`);
          }
          this.cartItemPrice = this.localCartItem.reduce((total, cartItem) => {
            return total + cartItem.item_count * cartItem.product.price;
          }, 0);
          this.$forceUpdate();
          break;
        }
        default:
          this.$router.replace({ name: 'Login' });
          break;
      }
    },

    async loadUserAccessItemsForAppointment() {

      const queryString = qs.stringify({
        user: this.user.id,
        appointment_type: this.appointmentType.id,
        date: this.localCartItem.date,
      });
      ({
        memberships: this.userMemberhips,
        class_passes: this.userClassPasses,
      } = await YogoApi.get(`/appointments/access-items-valid-for-appointment?${queryString}`));

      this.appointmentShowPaymentOptions = true;
    },

    setCartItemPrice(price) {
      this.cartItemPrice = price;
    },

    getSignupValidationClass(fieldName) {
      const field = this.$v.signupForm[fieldName];

      if (field) {
        return {
          'invalid': field.$invalid && field.$dirty,
        };
      }
    },
    getLoginValidationClass(fieldName) {
      const field = this.$v.loginForm[fieldName];

      if (field) {
        return {
          'invalid': field.$invalid && field.$dirty,
        };
      }
    },
    submitForm() {
      if (this.loginMode == 'login') {
        this.login();
      } else if (this.loginMode == 'signup') {
        this.signup();
      }
    },
    async signup() {
      this.showUserExists = false;

      this.$v.signupForm.$touch();

      if (this.$v.signupForm.$invalid) return;

      if (this.showRecurringTerms && !this.membershipTermsAccepted) {
        this.showForgotToAcceptMembershipTermsDialog = true;
        return;
      }

      if (!this.termsAccepted) {
        this.showForgotToAcceptTermsDialog = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_privacy_policy && !this.privacyPolicyAccepted) {
        this.showForgotToAcceptPrivacyPolicyDialog = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_consent_form && !this.consentFormAccepted) {
        this.showForgotToAcceptConsentFormDialog = true;
        return;
      }

      let userData = _pick(
          this.signupForm, [
            'first_name',
            'last_name',
            'email',
            'password',
            'phone',
            'address_1',
            'zip_code',
            'city',
            'country',
            'date_of_birth',
            'newsletter',
          ],
      );

      if (this.showSignupBranchfield) {
        userData.branches = this.signupForm.branchId ? [this.signupForm.branchId] : [];
      }

      // This is for the welcome email with email confirmation link.
      userData.emailConfirmationLink = this.emailConfirmationLink;

      this.loading = true;

      const response = await YogoApi.post('/users', userData);

      if (response === 'E_USER_EXISTS' || response === 'E_EMAIL_EXISTS') {
        this.loading = false;
        this.userExistsDialogEmail = userData.email;
        this.showUserExists = true;
        return;
      }

      if (this.rememberMe) {
        window.localStorage.setItem('accessToken', response.token);
        window.sessionStorage.removeItem('accessToken');
      } else {
        window.localStorage.removeItem('accessToken');
        window.sessionStorage.setItem('accessToken', response.token);
      }

      const user = await YogoApi.get('/users/' + response.user.id + '?populate=image');

      await this.$store.dispatch('updateUser', user.id);
      this.loginOrSignupCompleted();
    },

    async login() {

      this.$v.loginForm.$touch();

      if (this.$v.loginForm.$invalid) return;

      if (this.showRecurringTerms && !this.membershipTermsAccepted) {
        this.showForgotToAcceptMembershipTermsDialog = true;
        return;
      }

      if (!this.termsAccepted && this.cartItemRequiresPayment) {
        this.showForgotToAcceptTermsDialog = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_privacy_policy && !this.privacyPolicyAccepted) {
        this.showForgotToAcceptPrivacyPolicyDialog = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_consent_form && !this.consentFormAccepted) {
        this.showForgotToAcceptConsentFormDialog = true;
        return;
      }

      const response = await YogoApi.post('/login', this.loginForm);

      if (response === 'E_LOGIN_FAILED') {
        this.showLoginFailed = true;
        return;
      }

      if (response === 'E_USER_IS_NOT_CUSTOMER') {
        alert(this.$t('login.passCorrectButNotClient'));
        return;
      }

      if (!response.user || !response.token) {
        return;
      }

      if (this.rememberMe) {
        window.localStorage.setItem('accessToken', response.token);
        window.sessionStorage.removeItem('accessToken');
      } else {
        window.localStorage.removeItem('accessToken');
        window.sessionStorage.setItem('accessToken', response.token);
      }
      await this.$store.dispatch('updateUser', response.user.id);
      this.loginOrSignupCompleted();
    },

    async loginOrSignupCompleted() {
      // Maybe we just move on to buy something on this same page. In that case, the user must accept the terms again.
      this.termsAccepted = false;
      this.membershipTermsAccepted = false;
      this.privacyPolicyAccepted = false;
      this.consentFormAccepted = false;

      switch (this.cartItemType) {
        case 'event' :
        case 'event_first_installment' : {
          this.requestedEvent = await YogoApi.get('/events/' + this.localCartItem.itemId);

          const eventUser = await YogoApi.get('/users/' + this.user.id + '?populate[]=event_signups');

          if (
              !this.client.settings.customer_can_buy_multiple_seats_for_event
              && _find(
                  eventUser.event_signups,
                  (signup) => {
                    return signup.event == this.localCartItem.itemId;
                  },
              )) {
            this.loading = false;
            this.showAlreadySignedUpForEventDialog = true;
            return;
          }
          if (parseInt(this.requestedEvent.price) === 0) {
            const response = await YogoApi.post('/event-signups', {
              user: this.user.id,
              event: this.requestedEvent.id,
            });
            if (response === 'E_EVENT_IS_FULL') {
              alert(this.$t('global.fullyBooked'));
              ClientSite.closeOwnPopup();
              return;
            }
            if (response === 'E_USER_IS_ALREADY_SIGNED_UP_FOR_EVENT') {
              alert(this.$t('calendar.youAreAlreadySignedUp'));
              ClientSite.closeOwnPopup();
              return;
            }
            this.loading = false;
            this.showEventSignupSuccessDialog = true;
            return;
          }
          await YogoApi.post('/cart-items?destroyOthers=1', {
            item_type: this.cartItemType,
            item_id: this.localCartItem.itemId,
            user: this.user.id,
            item_count: this.localCartItem.itemCount,
          });

          break;
        }

        case 'class_pass_type' : {
          this.loading = true;
          const classPassType = await YogoApi.get('/class-pass-types/' + this.localCartItem.itemId);
          const userClassPasses = await YogoApi.get('/class-passes?user=' + this.user.id);
          const classPassesOfSameType = _filter(userClassPasses,
              classPass => classPass.class_pass_type == classPassType.id);

          // First check if the user is allowed to acquire this class pass
          if (
              classPassType.limited_number_per_customer &&
              classPassesOfSameType.length >= classPassType.max_number_per_customer
          ) {
            this.loading = false;
            this.alertDialogText = this.$t('prices.YouCantBuyMoreX', { x: classPassType.name });
            this.alertDialogConfirmText = this.$t('global.Ok');
            this.alertDialogClosedAction = 'closeOverlay';
            this.showAlertDialog = true;
            return;
          }

          if (parseInt(classPassType.price) === 0) {

            await YogoApi.post('/class-passes', {
              user: this.user.id,
              class_pass_type: classPassType.id,
            });

            this.loading = false;
            this.alertDialogText = this.$t('global.isCreatedOnProfile',
                { classPassTypeName: classPassType.name });
            this.alertDialogConfirmText = this.$t('global.Ok');
            this.showAlertDialog = true;
            this.alertDialogClosedAction = 'goToProfile';

            return;

          }

          await YogoApi.post('/cart-items?destroyOthers=1', {
            item_type: 'class_pass_type',
            item_id: this.localCartItem.itemId,
            user: this.user.id,
          });
          break;
        }

        case 'class_series_type':
        case 'class_series_type_first_installment': {
          this.loading = true;
          const queryString = qs.stringify({
            populate: [
              'customer_can_buy_class_series_type',
              'customer_has_class_series_type',
            ],
          });
          const classSeriesType = await YogoApi.get(`/class-series-types/${this.localCartItem.itemId}?${queryString}`);

          if (
              !classSeriesType.customer_can_buy_class_series_type
          ) {
            this.loading = false;
            if (classSeriesType.customer_has_class_series_type) {
              this.alertDialogText = this.$t('prices.YouAlreadyHaveThisClassSeries');
            } else {
              this.alertDialogText = this.$t('checkout.ThisClassSeriesIsNoLongerAvailable');
            }
            this.alertDialogConfirmText = 'Ok';
            this.alertDialogClosedAction = 'closeOverlay';
            this.showAlertDialog = true;
            return;
          }

          /*if (parseInt(classSeriesType.price) === 0) {

            await YogoApi.post('/class-passes', {
              user: this.user.id,
              class_pass_type: classSeriesType.id,
            });

            this.loading = false;
            this.alertDialogText = '"' + classSeriesType.name + '" er oprettet på din profil';
            this.alertDialogConfirmText = 'Ok';
            this.showAlertDialog = true;
            this.alertDialogClosedAction = 'goToProfile';

            return;

          }*/

          await YogoApi.post('/cart-items?destroyOthers=1', {
            item_type: this.cartItemType,
            item_id: this.localCartItem.itemId,
            user: this.user.id,
          });
          break;
        }

        case 'membership_type' : {
          const userMemberships = await YogoApi.get('/memberships?user=' + this.user.id + '&populate[]=membership_type');
          this.existingMembership = _find(userMemberships,
              membership => membership.membership_type.id == this.localCartItem.itemId && membership.status !== 'ended');
          if (this.existingMembership) {
            this.showMembershipExistsDialog = true;
            return;
          }
          if (!this.client.settings.customer_can_purchase_multiple_memberships) {
            const activeMemberships = _filter(
                userMemberships,
                membership => membership.status !== 'ended',
            );
            if (activeMemberships.length) {
              alert(this.$t('loginWithCart.YouAlreadyHaveAMembership'));
              return;
            }
          }
          const [membershipType] = await YogoApi.get(`/membership-types?id=${this.localCartItem.itemId}&populate[]=active_campaign&populate[]=payment_options`);
          await YogoApi.post('/cart-items?destroyOthers=1', {
            item_type: 'membership_type',
            item_id: this.localCartItem.itemId,
            user: this.user.id,
            payment_option: this.localCartItem.paymentOption || _find(
                membershipType.payment_options,
                { number_of_months_payment_covers: 1, for_sale: true },
            ).id,
            membership_campaign: membershipType.active_campaign_id || undefined,
            additional_details: {
              membership_start_date: this.client.settings.customer_can_choose_membership_start_date
              && this.membershipStartDate !== DateTime.now()
                  .setZone(this.client.timezone)
                  .toISODate()
                  ? this.membershipStartDate
                  : undefined,
            },
          });
          break;
        }

        case 'class' :
        case 'class_livestream': {
          const classQueryString = qs.stringify({
            id: this.localCartItem.itemId,
            populate: [
              'class_type',
              'ics_url',
              'ics_url_livestream',
              'user_can_sign_up_for_class',
              'user_can_sign_up_for_number_of_seats',
              'user_signup_count',
              'user_can_sign_up_for_livestream',
              'class_is_part_of_locked_class_series_type',
            ],
          });

          ({ classes: [this.requestedClass] } = await YogoApi.get(`/classes?${classQueryString}`));

          this.loading = false;

          if (
              !this.requestedClass.user_can_sign_up_for_class
              && this.requestedClass.class_is_part_of_locked_class_series_type
          ) {
            alert(this.$t('global.ThisClassIsPartOfAClassSeriesAndCanNotBeBookedDirectly'));
            this.closeOwnPopup();
            return;
          }

          this.additionalSeatsNeeded = this.localCartItem.itemCount - this.requestedClass.user_signup_count;
          if (this.additionalSeatsNeeded > this.requestedClass.user_can_sign_up_for_number_of_seats) {

            // If the user has an active membership, but it has reached its weekly/monthly limit or is
            // not usable for some other reason, show the reason.
            if (this.requestedClass.user_active_invalid_memberships && this.requestedClass.user_active_invalid_memberships.length) {
              alert(this.requestedClass.user_active_invalid_memberships[0].reason_for_membership_not_valid.localized_message);
            }

            //  Always prompt the user to buy another membership
            this.openUserHasNoAccessToClassDialog();
            return;
          }

          switch (this.cartItemType) {
            case 'class':
              await this.$refs.classSignupComponent.changeNumberOfSignupsForClass(
                  this.requestedClass,
                  this.localCartItem.itemCount,
                  { showSnackbar: false },
              );
              this.classSignupSuccess();
              break;
            case 'class_livestream':
              this.$refs.classSignupComponent.$emit('signUpForLivestream',
                  this.requestedClass,
                  { showSnackbar: false });
              break;
          }

          break;
        }

        case 'product': {
          if (_isArray(this.localCartItem)) {
            this.localCartItem = _map(this.localCartItem, item => {
              item.user = this.user.id;
              return item;
            });
            await YogoApi.post('/cart-items?destroyOthers=1', this.localCartItem[0]);
            await Promise.all(_map(this.localCartItem.slice(1),
                cartItem => YogoApi.post('/cart-items', cartItem)));
          } else {
            this.localCartItem.user = this.user.id;
            await YogoApi.post('/cart-items?destroyOthers=1', this.localCartItem);
          }

          break;
        }

        case 'appointment': {
          if (this.appointmentType.customer_point_of_payment === 'outsideYogo') {
            this.selectedAppointmentPaymentOption = 'outsideYogo';
            this.appointmentBook();
            return;
          }
          this.loadUserAccessItemsForAppointment();
          break;
        }
      }

      if (this.cartItemRequiresPayment) {

        this.loading = true;

        if (this.appliedDiscountCode) {
          if (!await this.createDiscountCodeCartItemAndHandleError()) {
            return;
          }

          // Check if the applied discount code raised the price because it went from
          // partial payment to full month payment
          const [userWithCartItems] = await YogoApi.get(`/users?id=${this.user.id}&populate[]=cart_items&populate[]=cart_items.product`);
          const discountCodeCartItem = _find(userWithCartItems.cart_items,
              { item_type: 'discount_code' });
          if (
              this.client.settings.memberships_always_renew_at_1st_of_month
              && DateTime.fromISO(this.membershipStartDate,
                  { zone: this.client.timezone }).day !== 1
              && userWithCartItems.cart_items[0].item_type === 'membership_type'
              && !userWithCartItems.cart_items[0].product.active_campaign
              && discountCodeCartItem
              && (
                  discountCodeCartItem.product.membership_discount_on_limited_number_of_payments
                  || discountCodeCartItem.product.type === 'discount_amount'
              )
          ) {
            alert(this.$t('checkout.DiscountCodeChangedPaymentPeriod'));
          }
        }

        window.localStorage.removeItem('localCart');
        this.startPayment();
      }

    },

    async createDiscountCodeCartItemAndHandleError() {
      const response = await YogoApi.post('/cart-items', {
        item_type: 'discount_code',
        discount_code: this.appliedDiscountCode,
        user: this.user.id,
      });
      if (response === 'E_DISCOUNT_CODE_NOT_FOUND') {
        this.loading = false;
        alert(this.$t('checkout.discountCodeNotFound'));
        return false;
      }
      if (response === 'E_DISCOUNT_CODE_CUSTOMER_LIMIT_REACHED') {
        this.loading = false;
        alert(this.$t('checkout.thisDiscountCodeIsNoLongerAvailable'));
        return false;
      }
      if (response === 'E_DISCOUNT_CODE_USE_PER_CUSTOMER_LIMIT_REACHED') {
        this.loading = false;
        alert(this.$t('checkout.youHaveAlreadyUsedThisDiscountCodeTheMaximumNumberOfTimes'));
        return false;
      }
      if (response.substr(0, 2) === 'E_') {
        this.loading = false;
        alert(response);
        return false;
      }
      return true;
    },

    async openUserHasNoAccessToClassDialog() {
      ({
        classPassTypes: this.classValidClassPassTypes,
        membershipTypes: this.classValidMembershipTypes,
      } = await YogoApi.post(
          `/class-types/${this.requestedClass.class_type.id}/purchase-options`,
          {
            user: this.user.id,
            attendanceType: this.cartItemType, // 'class' or 'class_livestream'
            requiredNumberOfSeats: this.localCartItem.itemCount,
          },
      ));

      this.classValidClassPassTypes = _sortBy(
          this.classValidClassPassTypes,
          ['price'],
      );

      this.selectedClassPaymentOption = this.classValidMembershipTypes.length
          ? `membership_type-${this.classValidMembershipTypes[0].id}-${this.classValidMembershipTypes[0].payment_options[0].id}`
          : `class_pass_type-${this.classValidClassPassTypes[0].id}`;

      this.showUserHasNoAccessToClassDialog = true;
    },

    purchaseAccessMultipleClassPassText(classPassType, count) {
      const requiredNumberOfClassPasses = Math.ceil(count / classPassType.number_of_classes);
      return `${requiredNumberOfClassPasses} x ${this.formatCurrency(classPassType.has_member_discount ? classPassType.price_with_member_discount : classPassType.price)}`;
    },

    async startPayment() {
      switch (this.client.settings.payment_service_provider) {
        case 'reepay':
          this.reepayStartPayment();
          break;
        case 'stripe':
          this.stripeStartPayment();
          break;
      }
    },

    alertDialogClosed() {
      switch (this.alertDialogClosedAction) {
        case 'goToProfile':
          this.$router.push({ name: 'MyProfile' });
          break;
        case 'reset':
          this.showAlertDialog = false;
          this.logout();
          break;
        case 'closeOverlay':
          ClientSite.closeOwnPopup();
          break;
      }
    },

    classSignupSuccess() {
      this.classSignupSuccessDialogAddToCalendarLink = this.cartItemType === 'class_livestream'
          ? this.requestedClass.ics_url_livestream
          : this.requestedClass.ics_url;
      this.showClassSignupSuccessDialog = true;
    },

    classSignupFailure(error) {
      switch (error) {
        case 'E_CLASS_HAS_STARTED':
          alert(this.$t('calendar.classHasStarted'));
          this.backToCalendar();
          break;
        case 'E_CLASS_CANCELLED':
          alert(this.$t('calendar.classIsCancelled'));
          this.backToCalendar();
          break;
        case 'E_NO_ACCESS_TO_CLASS':
        case 'E_CUSTOMER_HAS_NO_VALID_CLASS_PASS_OR_MEMBERSHIP':
          // If the user has an active membership, but it has reached its weekly/monthly limit or is
          // not usable for some other reason, show the reason.
          if (this.requestedClass.user_active_invalid_memberships && this.requestedClass.user_active_invalid_memberships.length) {
            alert(this.requestedClass.user_active_invalid_memberships[0].reason_for_membership_not_valid.localized_message);
          }
          // Always prompt the user to buy another membership
          this.openUserHasNoAccessToClassDialog();
          break;
        case 'E_USER_CANCELLED_SIGNUP':
          this.logout();
          break;
        case 'E_ALREADY_SIGNED_UP':
          alert(this.$t('calendar.youAreAlreadySignedUp'));
          this.backToCalendar();
          break;
        default: {
          this.logout();
          const message = this.$t('calendar.classSignupError', {
            errorMessage: error,
          });
          alert(message);
          break;
        }
      }
    },

    cancelMembershipExistsDialog() {
      this.logout();
      this.showMembershipExistsDialog = false;
    },

    dismissUserHasNoAccessToClassDialog() {
      this.showUserHasNoAccessToClassDialog = false;
      this.logout();
    },

    logout() {
      this.$store.commit('clearUser');
      window.sessionStorage.removeItem('accessToken');
      window.localStorage.removeItem('accessToken');
    },

    backToEventList() {
      if (ClientSite.isRunningInIframe()) {
        ClientSite.closeOwnPopup();
      } else {
        this.$router.push({
          name: 'Events',
        });
      }
    },
    backToCalendar() {
      if (ClientSite.isRunningInIframe()) {
        ClientSite.closeOwnPopup();
      } else {
        this.$router.push({
          name: 'Schedule',
        });
      }
    },
    closeOwnPopup() {
      if (ClientSite.isRunningInIframe()) {
        ClientSite.closeOwnPopup();
      }
    },
    backToMyProfile() {
      this.$router.push({
        name: 'MyProfile',
      });
    },
    async applyDiscountCode() {
      if (!this.discountCodeInput) return;

      this.appliedDiscountCode = this.discountCodeInput;
      this.discountCodeInput = '';
    },
    removeAppliedDiscountCode() {
      this.discountCodeInput = '';
      this.appliedDiscountCode = '';
    },
    async addToCart(cartItem) {

      this.loading = true;

      await Promise.all([...Array(cartItem.itemCount).keys()].map(async () => {
        // console.log("@smart---addToCart\n", cartItem);
        await YogoApi.post('/cart-items', cartItem);
      }));
      await this.$store.dispatch('updateUser');

      this.$router.push({ name: 'Checkout' });
      this.loading = false;

    },
    async checkAppointmentAvailability() {
      const queryString = qs.stringify({
        date: this.localCartItem.date,
        appointment_type_id: this.localCartItem.appointment_type_id,
        teacher_id: this.localCartItem.teacher_id,
        start_time: this.localCartItem.start_time,
        branch_id: this.localCartItem.branch_id,
      });
      const result = await YogoApi.get(`/appointment-available-timeslots/check?${queryString}`);
      if (!result) {
        alert(this.$t('global.TheAppointmentTimeslotIsNoLongerAvailable'));
        this.closeOwnPopup();
      }
      return result;
    },
    async appointmentPay() {
      if (!this.termsAccepted) {
        this.showForgotToAcceptTermsDialog = true;
        return;
      }
      if (this.client.settings.customer_must_accept_the_privacy_policy && !this.privacyPolicyAccepted) {
        this.showForgotToAcceptPrivacyPolicyDialog = true;
        return;
      }
      if (this.client.settings.customer_must_accept_the_consent_form && !this.consentFormAccepted) {
        this.showForgotToAcceptConsentFormDialog = true;
        return;
      }

      if (!this.checkAppointmentAvailability()) return;

      if (this.selectedAppointmentPaymentOption === 'directPurchase') {
        // Direct payment for the appointment
        const cartItem = {
          item_type: 'appointment',
          item_id: this.localCartItem.appointment_type_id,
          appointment_details: {
            date: this.localCartItem.date,
            appointment_type_id: this.localCartItem.appointment_type_id,
            teacher_id: this.localCartItem.teacher_id,
            start_time: this.localCartItem.start_time,
            branch_id: this.localCartItem.branch_id,
          },
          user: this.user.id,
          destroyOthers: true,
        };
        await YogoApi.post('/cart-items', cartItem);
      } else {
        // Buying a class pass or a membership for the appointment
        const cartItem = {
          item_type: this.selectedAppointmentPaymentOption.substr(0, 15),
          item_id: this.selectedAppointmentPaymentOption.substr(16),
          use_immediately_after_purchase: {
            item_type: 'appointment',
            date: this.localCartItem.date,
            appointment_type_id: this.localCartItem.appointment_type_id,
            teacher_id: this.localCartItem.teacher_id,
            start_time: this.localCartItem.start_time,
            branch_id: this.localCartItem.branch_id,
          },
          user: this.user.id,
          destroyOthers: true,
        };
        await YogoApi.post('/cart-items', cartItem);
      }

      if (this.appliedDiscountCode) {
        if (!await this.createDiscountCodeCartItemAndHandleError()) {
          return;
        }
      }
      // window.localStorage.removeItem('localCart');
      this.startPayment();
    },

    async appointmentBook() {
      // Check that appointment is still available
      if (!this.checkAppointmentAvailability()) return;

      // Make appointment
      const postData = {
        date: this.localCartItem.date,
        appointment_type_id: this.localCartItem.appointment_type_id,
        customer_id: this.user.id,
        teacher_id: this.localCartItem.teacher_id || undefined,
        start_time: this.localCartItem.start_time,
        branch_id: this.localCartItem.branch_id || undefined,
        paymentOption: this.selectedAppointmentPaymentOption,
      };
      let newAppointment = await YogoApi.post(
          `/appointments`,
          postData,
          {
            headers: { 'X-Yogo-Client-Accepts-Extended-Error-Format': 1 },
          },
      );
      if (newAppointment.error) {
        // TODO Handle other errors with extended error handling.
        if (newAppointment.error.type === 'appointmentOverlaps') {
          if (newAppointment.error.overlappingObjects.customer.length) {
            alert(this.$t('global.YouAlreadyHaveAnAppointmentOrClassAtThisTime'));
          } else {
            alert(this.$t('global.TheAppointmentTimeslotIsNoLongerAvailable'));
          }
          this.closeOwnPopup();
        }
      }

      // Show alert that the appointment has been created
      // Get additional data for the confirmation alert
      const queryString = qs.stringify({
        populate: [
          'appointment_type',
          'customer',
          'teacher',
          'room.branch',
        ],
      });
      newAppointment = await YogoApi.get(`/appointments/${newAppointment.id}?${queryString}`);
      let roomText = newAppointment.room
          ? `${this.$t('global.Room')}: ${newAppointment.room.name}`
          : '';
      if (newAppointment.room && newAppointment.room.branch) {
        roomText += ` (${newAppointment.room.branch.name})`;
      }
      this.alertDialogText = this.$t('global.YourAppointmentHasBeenBooked', {
        appointmentTypeName: newAppointment.appointment_type.name,
        teacherName: `${newAppointment.teacher.first_name} ${newAppointment.teacher.last_name}`.trim(),
        roomText,
        dateFormatted: this.formatDate(newAppointment.date),
        timeText: `${newAppointment.start_time.substr(0, 5)}-${newAppointment.end_time.substr(
            0,
            5)}`,
      });

      this.alertDialogConfirmText = 'Ok';
      this.alertDialogClosedAction = 'goToProfile';
      this.showAlertDialog = true;
    },

    async classPayAndSignup() {
      // Buying a class pass or a membership to sign up a class
      const split = this.selectedClassPaymentOption.split('-');
      const itemType = split[0];
      const itemId = parseInt(split[1]);
      const paymentOptionId = parseInt(split[2]);

      if (this.localCartItem.itemCount > 1) {
        const classPassType = _find(this.classValidClassPassTypes, { id: parseInt(itemId) });
        const numberOfItems = Math.ceil(this.localCartItem.itemCount / classPassType.number_of_classes);
        for (let i = 0; i < numberOfItems; i++) {
          const cartItem = {
            item_type: itemType,
            item_id: itemId,
            use_immediately_after_purchase: {
              item_type: this.cartItemType, // 'class' or 'class_livestream'
              class_id: this.requestedClass.id,
              numberOfSeats: i === 0
                  ? (this.localCartItem.itemCount % classPassType.number_of_classes) || classPassType.number_of_classes
                  : classPassType.number_of_classes,
            },
            payment_option: paymentOptionId || undefined,
            user: this.user.id,
            destroyOthers: i === 0,
          };
          if (itemType === 'membership_type') {
            const membershipType = _find(this.classValidMembershipTypes, { id: parseInt(itemId) });
            const paymentOption = _find(membershipType.payment_options,
                { id: parseInt(paymentOptionId) });
            if (membershipType.userIsEligibleForCampaign && paymentOption.number_of_months_payment_covers === 1) {
              cartItem.membership_campaign = membershipType.active_campaign_id;
            }
          }
          await YogoApi.post('/cart-items', cartItem);
        }
      } else {
        const cartItem = {
          item_type: itemType,
          item_id: itemId,
          use_immediately_after_purchase: {
            item_type: this.cartItemType, // 'class' or 'class_livestream'
            class_id: this.requestedClass.id,
            numberOfSeats: this.localCartItem.itemCount,
          },
          payment_option: paymentOptionId || undefined,
          user: this.user.id,
          destroyOthers: true,
        };
        await YogoApi.post('/cart-items', cartItem);
      }

      this.$router.push({ name: 'Checkout' });
    },

    async setMembershipStartDateDisabledDates() {
      if (!this.client) {
        console.log('client not loaded yet');
        return false;
      }
      const maxDaysInFuture = this.client.settings.customer_can_choose_membership_start_date_max_days_in_future;

      const lastDisallowedDate = DateTime.now()
          .setZone(this.client.timezone)
          .minus({ days: 1 })
          .toJSDate();
      const firstDisallowedDate = DateTime.now()
          .setZone(this.client.timezone)
          .plus({ days: maxDaysInFuture + 1 })
          .toJSDate();
      this.membershipStartDateDisabledDates = {
        to: lastDisallowedDate,
        from: firstDisallowedDate,
      };
    },
  },
  watch: {
    stateReady(newStateReady) {
      if (newStateReady) {
        this.setMembershipStartDateDisabledDates();
        this.initCartItem();
      }
    },
    selectedAppointmentPaymentOption(paymentOption) {
      if (paymentOption.match(/^class_pass_type_\d+$/)) {
        const classPassTypeId = parseInt(paymentOption.substr(16));
        const classPassType = _find(
            this.appointmentType.valid_class_pass_types,
            cpt => cpt.id === classPassTypeId,
        );
        this.cartItemPrice = classPassType.price;
      }
      if (paymentOption.match(/^membership_type_\d+$/)) {
        const membershipTypeId = parseInt(paymentOption.substr(16));
        const membershipType = _find(
            this.appointmentType.valid_membership_types,
            mt => mt.id === membershipTypeId,
        );
        this.cartItemPrice = membershipType.id;
      }
      if (paymentOption === 'directPurchase') {
        this.cartItemPrice = this.appointmentType.price;
      }
    },
    'localCartItem.itemCount': function () {
      switch (this.localCartItem.itemType) {
        case 'event':
          this.cartItemName = `${this.localCartItem.itemCount} x ${this.currentEvent.name}`;
          this.cartItemPrice = this.localCartItem.itemCount * this.currentEvent.price;
          break;
      }
    },
    membershipStartDate() {
      this.initCartItem();
    },
  },
  validations() {
    const v = {
      loginForm: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
      signupForm: {
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          passwordStrength(value) {
            const length = value.length >= 6;
            if (!length) return false;
            const upperCase = !!value.match(/[A-Z]+/);
            const lowerCase = !!value.match(/[a-z]+/);
            const numbers = !!value.match(/[0-9]+/);
            return upperCase && lowerCase && numbers;
          },
        },
      },
    };
    if (this.client.settings.signup_phone_field_required) {
      v.signupForm.phone = {
        required,
      };
    }

    if (this.client.settings.signup_date_of_birth_field_required) {
      v.signupForm.date_of_birth = {
        required,
      };
    }

    if (this.client.settings.signup_show_address_fields && this.client.settings.signup_address_fields_required) {
      v.signupForm.address_1 = {
        required,
      };
      v.signupForm.zip_code = {
        required,
      };
      v.signupForm.city = {
        required,
      };
    }
    return v;
  },
};
</script>

<style lang="scss" scoped>

.leading-tight {
  line-height: 1.2;
}

img.logo-svg {
  width: 120px;
  height: 80px;
  padding: 0;
  margin: 0 auto !important;
  display: block;
  @media (min-width: 1024px) {
    margin: 0 !important;
  }
}

img.logo-bitmap {
  width: 160px;
  height: auto;
  padding: 0;
  margin: 0 auto !important;
  display: block;
  @media (min-width: 1024px) {
    margin: 0 !important;
  }
}

.product-item {
  padding-bottom: 5px;
}

.total {
  padding-top: 5px;
}

.terms-dialog {
  z-index: 12;
}

</style>

