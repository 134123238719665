<template>
   <div class="min-h-screen lg:flex lg:justify-center bg-white lg:bg-transparent pb-8">
    <div class="login narrow-wrapper w-full max-w-xl m-auto" :class="{ 'wrapper' : !mobileAppMode }">
      <div class="bg-white md:pl-6 md:pr-6 md:pb-6 pt-4 md:mt-4 lg:mt-8 lg:pb-16">
        <div class="my-6">
          <ModalLogo></ModalLogo>
        </div>
        <h2 class="flex-wrap mb-8 text-center">
          <div>
            {{ $t('login.welcome') }}
            </div>
            <div>
              {{ client.name }}
            </div>
        </h2>

        <div class="mb-4 flex items-center flex-wrap" v-if="!client.settings.signup_closed_with_token">
          <p class="mb-2 mr-2">
            {{ $t('login.forSignupCreateProfile') }}.
          </p>
          <router-link :to="{name: 'Signup'}">
            <button class="btn btn-primary mb-2 font-bold whitespace-no-wrap">
              {{ $t('login.createProfile') }}
            </button>
          </router-link>
        </div>

        <p class="mb-4" v-if="!client.settings.signup_closed_with_token">
          {{ $t('login.alreadyHaveProfile') }}
        </p>

        <div class="flex-column">

          <form novalidate @submit.prevent="submitForm">

            <InputField
              :label="$t('login.emailLabel')"
              :class="getValidationClass('email')"
              type="email"
              autocomplete="email"
              v-model="form.email"
            >
              <template v-slot:errors>
                <span class="error" v-if="!$v.form.email.required">{{ $t('login.typeEmail') }}</span>
                <span class="error" v-else-if="!$v.form.email.email">{{ $t('login.notValidEmail') }}</span>
              </template>
            </InputField>

            <InputField
              :label="$t('login.passwordLabel')"
              :inputLabel="$t('login.passwordLabel')"
              :class="getValidationClass('password')"
              type="password"
              v-model="form.password"
            >
              <template v-slot:errors>
                <span class="error" v-if="!$v.form.password.required">
                  {{ $t('login.passMustBeEntered') }}
                </span>
              </template>
            </InputField>

            <div class="login-failed" v-if="loginFailed">
              {{ $t('login.wrongEmailOrPassword') }}
            </div>

            <button class="w-full btn btn-primary my-6" type="submit">
              {{ $t('login.signin') }}
            </button>

            <div class="flex items-center flex-col">
              <label class="checkbox mb-6">
                <input type="checkbox" v-model="rememberMe">
                <span>{{ $t('login.rememberMeLabel') }}</span>
              </label>
              <router-link :to="{name: 'ResetPassword' }" class="cursor-pointer font-bold underline">
                {{ $t('login.forgotPass') }}
              </router-link>
            </div>
          </form>
        </div>
      </div>


      <md-dialog :md-active.sync="showLoginFailed">
        <md-dialog-title>{{ $t('login.loginFailed') }}</md-dialog-title>
        <md-dialog-content>
          {{ $t('login.wrongEmailorCode') }}
        </md-dialog-content>
        <md-dialog-actions>
          <button
            class="btn btn-primary"
            :class="{ 'btn-small-app': mobileAppMode }"
            @click.prevent="showLoginFailed = false"
          >
            {{ $t('global.Ok') }}
          </button>
        </md-dialog-actions>
      </md-dialog>

    </div>
  </div>
</template>

<script>
  import qs from 'qs';
  import {validationMixin} from 'vuelidate'
  import YogoApi from '../gateways/YogoApi'
  import {mapGetters} from 'vuex'
  import {
    required,
    email,
  } from 'vuelidate/lib/validators'

  import _pick from 'lodash/pick'
  import InputField from './InputField.vue'
  import ModalLogo from './ModalLogo'
  import ClientSite from "../gateways/ClientSite"

  export default {
    components: {
      ModalLogo,
      InputField
    },
    mixins: [validationMixin],
    data() {
      return {
        email: '',
        password: '',
        rememberMe: false,
        loginFailed: false,
        form: {
          email: '',
          password: '',
        },

        showLoginFailed: false,
      }
    },
    computed: {
      ...mapGetters([
        'client',
        'clientLogoFilename',
        'requestedRoute',
        'mobileAppMode',
      ]),
    },
    mounted: function () {
      // Clear gift card details. They might be lingering after a cancelled payment.
      localStorage.removeItem('yogoFrontend_giftCardDetails');
    },
    methods: {
      getValidationClass(fieldName) {
        const field = this.$v.form[fieldName]

        if (field) {
          return {
            'invalid': field.$invalid && field.$dirty,
          }
        }
      },
      async submitForm() {

        this.$v.$touch()

        if (this.$v.$invalid) return

        const response = await YogoApi.post('/login', this.form)

        if (response === 'E_LOGIN_FAILED') {
          this.showLoginFailed = true
          return
        }

        if (response === 'E_USER_IS_NOT_CUSTOMER') {
          alert(this.$t('login.passCorrectButNotClient'))
          return
        }

        if (!response.user || !response.token) {
          return
        }
        if (this.rememberMe) {
          window.localStorage.setItem('accessToken', response.token)
          window.sessionStorage.removeItem('accessToken')
        } else {
          window.localStorage.removeItem('accessToken')
          window.sessionStorage.setItem('accessToken', response.token)
        }
        await this.$store.dispatch('updateUser', response.user.id)

        const queryParams = qs.parse(document.location.search.substr(1));
        if (queryParams && queryParams.closeOwnPopupAfterLogin) {
          ClientSite.closeOwnPopup();
        }
        if (this.requestedRoute) {
          if (this.requestedRoute.name === 'Login' || !this.requestedRoute.name) {
            ClientSite.closeOwnPopup()
          }
          const requestedRoute = _pick(this.requestedRoute, ['name', 'params', 'query'])
          this.$store.commit('setRequestedRoute', null)
          this.$router.push(requestedRoute)
        } else {
          this.$router.push({name: 'MyProfile'})
        }
      },
    },
    validations: {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    },
  }
</script>

<style lang="scss" scoped>


</style>
