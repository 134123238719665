<template>
  <div class="prices" :class="{ 'wrapper' : !mobileAppMode }">

    <h1 class="hidden-sm mb-8">
      <span>{{ $t('global.prices') }}</span>
    </h1>

    <div v-if="!mobileAppMode" class="space8"></div>

    <loadingspinner v-if="loading"></loadingspinner>

    <div v-else>
      <div
          v-if="visiblePriceGroups.length > 1"
          class="flex"
          :class="mobileAppMode ? '' : 'mb-6 lg:mb-12'"
      >
        <div
            class="w-full h-auto md:w-4/12"
            :class="mobileAppMode ? 'mt-5 mx-5 yogo-select-app' : 'yogo-input'"
        >
          <select
              class="h-full"
              :class="{ 'rounded-md-important font-bold' : mobileAppMode }"
              name="priceGroup"
              v-model="selectedPriceGroup"
          >
            <option
                v-for="priceGroup in visiblePriceGroups" :value="priceGroup.id"
                :key="'price_group_'+priceGroup.id">
              {{ priceGroup.name }}
            </option>
          </select>
        </div>

        <div v-if="!mobileAppMode">
          <div class="space4"></div>
          <div class="space8"></div>
        </div>
      </div>
      <ul>

        <li
            v-for="(priceItem, idx) in sortedPriceItems"
            :key="'priceitem_' + idx"
            :class="mobileAppMode ? 'mx-5 rounded-lg bg-white shadow-lg' : 'theme--frame-box'"
        >

          <div class="flex__grid--thirds align--top" v-if="priceItem.priceItemType === 'gift_card'">
            <div class="flex__1-3--margin">
              <img
                  class="w-full"
                  :class="{'rounded-lg' : mobileAppMode}"
                  :src="Imgix(giftCardPricesImage.filename, {w:320, h:320, fit: 'crop'})"
                  alt=""
                  v-if="giftCardPricesImage"
              >
            </div>
            <div class="space4"></div>
            <div class="flex__1-3--margin">
              <h3 class="font-bold mb-2">{{ client.settings.gift_card_prices_title }}</h3>
              <nl2br tag="p" :text="client.settings.gift_card_prices_description"></nl2br>
            </div>
            <div class="space4"></div>
            <div class="flex__1-3--margin">
              <button
                  @click="buyGiftCard()"
                  class="btn btn-primary"
                  :class="{ 'btn-primary-app w-full mt-2': mobileAppMode }"
                  :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              >
                {{ $t('global.buy') }}
              </button>
            </div>
          </div>
          <div v-if="!mobileAppMode" class="space4"></div>

          <div class="flex__grid--thirds align--top"
               v-if="priceItem.priceItemType === 'membership_type'">
            <div class="flex__1-3--margin">
              <img class="width--100"
                   :class="{'rounded-lg' : mobileAppMode}"
                   :src="Imgix(priceItem.image.filename, {h:640, w:640, fit:'crop'})" alt=""
                   v-if="priceItem.image">
            </div>
            <div class="space4"></div>
            <div class="flex__1-3--margin">
              <h3 class="font-bold mb-2">{{ priceItem.name }}</h3>
              <nl2br tag="p" :text="priceItem.description"></nl2br>
            </div>
            <div class="space4"></div>
            <div class="flex__1-3--margin">

              <div class="space2"></div>

              <div v-if="priceItem.payment_options.length > 1">
                <div class="yogo-input my-4">
                  <label>{{ $t('prices.paymentOptions') }}</label>
                  <select v-model="selectedPaymentOption[priceItem.id]"
                          @change="$forceUpdate()"
                          :class="{'rounded-md-important' : mobileAppMode }"
                  >
                    <option v-for="paymentOption in priceItem.payment_options"
                            :value="paymentOption.id"
                            :key="'payment_option_' + paymentOption.id">
                      {{ paymentOption.nameThatUserSees }}
                    </option>
                  </select>
                </div>
                <p class="bold upper">
                  {{ getSelectedMembershipTypePaymentOption(priceItem).priceTextThatUserSees }}
                </p>
              </div>

              <div
                  v-else-if="priceItem.payment_options[0].payment_amount > 0 || !client.settings.hide_zero_prices">
                <label>{{ $t('prices.payment') }}: {{
                    priceItem.payment_options[0].nameThatUserSees
                  }} </label>
                <p class="bold upper">{{
                    priceItem.payment_options[0].priceTextThatUserSees
                  }} </p>
              </div>

              <div v-if="priceItem.registration_fee">
                {{ client.settings.membership_registration_fee_name }}:
                {{ formatCurrency(priceItem.registration_fee) }}
              </div>


              <div class="customer-has-this line-height--20"
                   v-if="customerHasMembershipType(priceItem)">
                {{ $t('global.haveMembership') }}
                <br>
                <div v-if="!mobileAppMode">
                  <div v-for="membership in getCustomerMembershipsForMembershipType(priceItem)"
                       :key="'membership_' + membership.id">
                    <router-link
                        class="underline"
                        :to="{name: 'Membership', params: {id: membership.id}}">{{
                        $t('prices.seeMembership')
                      }}
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="space2"></div>
              <div
                  v-if="priceItem.has_max_number_of_memberships &&
                priceItem.membershipCount >= priceItem.max_number_of_memberships &&
                !customerHasMembershipType(priceItem)">
                <a
                    :href="'mailto:' + client.email + '?subject=Venteliste%20til%20' + encodeURIComponent(priceItem.name)"
                    class="md-primary">{{ $t('global.waitingList') }}</a>
              </div>


              <div v-else-if="!customerHasMembershipType(priceItem)">

                <button @click="buyMembershipType(priceItem)"
                        class="btn btn-primary"
                        :class="{ 'btn-primary-app w-full mt-2': mobileAppMode }"
                        :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
                >
                  <span v-if="priceItem.payment_options[0].payment_amount === 0
                    && client.settings.hide_zero_prices">
                    {{ $t('prices.Select') }}
                  </span>
                  <span v-else>
                    {{ $t('global.buy') }}
                  </span>
                </button>


              </div>

              <div v-if="!mobileAppMode" class="space4"></div>
            </div>
          </div>
          <div class="space4"></div>

          <div class="flex__grid--thirds align--top"
               v-if="priceItem.priceItemType === 'class_pass_type'">
            <div class="flex__1-3--margin">
              <img class="width--100"
                   :class="{'rounded-lg' : mobileAppMode}"
                   :src="Imgix(priceItem.image.filename, {w:640,h:640,fit: 'crop'})" alt=""
                   v-if="priceItem.image">
            </div>
            <div class="space4"></div>
            <div class="flex__1-3--margin">
              <h3 class="font-bold mb-2">{{ priceItem.name }}</h3>
              <nl2br tag="p" :text="priceItem.description"></nl2br>
            </div>
            <div class="space4"></div>
            <div class="flex__1-3--margin">
              <div v-if="(priceItem.has_member_discount ? priceItem.price_with_member_discount : priceItem.price) > 0
              || !client.settings.hide_zero_prices">
                <p class="upper" :class="{strikethrough: priceItem.has_member_discount}">
                  {{ formatCurrency(priceItem.price) }}</p>

                <div v-if="priceItem.has_member_discount">
                  <p class="bold upper">{{ $t('prices.MemberPrice') }}
                    {{ formatCurrency(priceItem.price_with_member_discount) }}</p>
                </div>
              </div>

              <div class="space2"></div>

              <div class="list__space--between">

                <button @click="buyClassPassType(priceItem)" class="btn btn-primary"
                        v-if="canBuyClassPassType(priceItem, customerClassPasses)"
                        :class="{ 'btn-primary-app w-full mt-2': mobileAppMode }"
                        :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
                >
                  <span v-if="(priceItem.has_member_discount ? priceItem.price_with_member_discount : priceItem.price) === 0
                    && client.settings.hide_zero_prices">
                    {{ $t('prices.Select') }}
                  </span>
                  <span v-else>
                    {{ $t('global.buy') }}
                  </span>
                </button>
                <div v-if="limitedNumberPerCustomerUsedUp(priceItem, customerClassPasses)">{{
                    $t('prices.youCantBuyMoreClassPasses')
                  }}
                </div>

              </div>
            </div>
          </div>
          <div v-if="!mobileAppMode" class="space4"></div>

          <div class="flex__grid--thirds align--top"
               v-if="priceItem.priceItemType === 'class_series_type'">
            <div class="flex__1-3--margin">
              <img class="width--100"
                   :class="{'rounded-lg' : mobileAppMode}"
                   :src="Imgix(priceItem.image.filename, {w:640,h:640,fit: 'crop'})" alt=""
                   v-if="priceItem.image">
            </div>
            <div class="space4"></div>
            <div class="flex__1-3--margin">
              <h3 class="font-bold mb-2">
                {{ priceItem.name }}
              </h3>
              <nl2br tag="p" :text="priceItem.description"></nl2br>
            </div>
            <div class="space4"></div>
            <div class="flex__1-3--margin">

              <div v-if="priceItem.is_available_for_purchase">
                <div v-if="(priceItem.has_member_discount ? priceItem.price_with_member_discount : priceItem.price) > 0
                  || !client.settings.hide_zero_prices">
                  <div
                      :class="{strikethrough: priceItem.has_member_discount, bold: !priceItem.has_member_discount}">
                    {{ formatCurrency(priceItem.current_price) }}
                  </div>
                  <div class="bold upper" v-if="priceItem.has_member_discount">
                    {{ $t('prices.MemberPrice') }}
                    {{ formatCurrency(priceItem.current_price_with_member_discount) }}
                  </div>
                </div>

                <div v-if="priceItem.has_started">
                  ({{
                    $t('global.ReducedPriceNClassesRemaining',
                        { n: priceItem.number_of_remaining_classes_and_livestream_classes })
                  }})
                </div>
              </div>
              <div v-else-if="priceItem.fully_booked_remaining_classes.length > 0">
                {{ $t('global.FullyBooked') }}
              </div>
              <div v-else>
                {{ $t('global.NotAvailable') }}
              </div>

              <div class="space2"></div>

              <div class="" v-if="priceItem.displayClasses.length">
                <YogoShowLessWrapper
                    :rows="priceItem.displayClasses"
                >
                  <template v-slot:default="slotProps">
                    <div class="min-w-full divide-y divide-gray-300 flex--row"
                         v-for="classObj in slotProps.rows" :key="classObj.id">
                      <div class="leading-loose flex items-center">
                        <span class="mr-2">
                        {{ formatDate(classObj.date, { weekday: true, capitalize: true }) }},
                        {{ classObj.start_time }}
                        </span>
                        <span v-if="classObj.isLivestreamClass" class="w-4 h-4">
                              <ScreenIcon/>
                              <md-tooltip>{{ $t('global.Livestream') }}</md-tooltip>
                            </span>
                      </div>
                    </div>
                  </template>
                </YogoShowLessWrapper>
              </div>
              <div v-else>
                {{ $t('global.NoClasses') }}
              </div>

              <div class="space2"></div>

              <div>

                <div>
                  <button @click="buyClassSeriesType(priceItem)"
                          class="btn btn-primary mr-4"
                          v-if="priceItem.customer_can_buy_class_series_type"
                          :class="{ 'btn-primary-app w-full mt-2': mobileAppMode }"
                          :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
                  >
                  <span v-if="(priceItem.has_member_discount ? priceItem.price_with_member_discount : priceItem.price) === 0
                    && client.settings.hide_zero_prices">
                    {{ $t('prices.Select') }}
                  </span>
                    <span v-else>
                    {{ $t('global.buy') }}
                  </span>
                  </button>
                  <button
                      v-if="showClassSeriesInstallmentPaymentOption(priceItem)"
                      class="btn btn-primary"
                      :class="mobileAppMode ? 'btn-primary-app w-full mt-2' : 'my-2'"
                      @click="buyClassSeriesType(priceItem, payInInstallments = true)"
                  >
                    {{ $t('event.SignUpPayInInstallments') }}
                  </button>
                  <a
                      v-if="showClassSeriesInstallmentPaymentOption(priceItem)"
                      href="#"
                      @click.prevent="openClassSeriesInstallmentsModal(priceItem)"
                      :class="mobileAppMode ? 'btn-small-app my-1': 'my-2 underline'"
                  >
                    {{ $t('event.ShowInstallments') }}
                  </a>
                </div>

                <div
                    class="font-bold"
                    v-if="priceItem.customer_has_class_series_type"
                >
                  {{ $t('prices.YouAlreadyHaveThisClassSeries') }}
                </div>

              </div>
            </div>
          </div>
          <div v-if="!mobileAppMode" class="space4"></div>
        </li>

      </ul>
    </div>
    <div v-if="!mobileAppMode" class="space8"></div>


    <md-dialog-alert :md-content="alertDialogText"
                     :md-confirm-text="alertDialogConfirmText"
                     :md-active.sync="showAlertDialog"
    />

    <modal :show.sync="showClassSeriesInstallmentModal">

      <template v-slot:title>{{
          classSeriesInstallmentModalClassSeries ? classSeriesInstallmentModalClassSeries.name : ''
        }}
      </template>

      <template v-slot:content>
        <div>
          <h3>{{ $t('event.Installments') }}</h3>
          <div class="inline-block min-w-full py-2 align-middle">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                <tr>
                  <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {{ $t('global.Date') }}
                  </th>
                  <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{
                      $t('global.Amount')
                    }}
                  </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(row, idx) in classSeriesModalInstallmentRows"
                    :key="'installment_' + idx">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {{ row.date }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <span v-if="row.has_member_discount">
                      <span class="strikethrough">{{ row.amount }}</span><br>
                      <span class="font-bold">{{ row.amount_with_member_discount }}</span>
                    </span>
                    <span v-else>{{ row.amount }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>

    </modal>

  </div>
</template>

<script>
import YogoApi from '../gateways/YogoApi';
import LoadingSpinner from './LoadingSpinner.vue';
import YogoShowLessWrapper from './ui/YogoShowLessWrapper.vue';
import { mapGetters } from 'vuex';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _isObject from 'lodash/isObject';
import _each from 'lodash/each';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _sortBy from 'lodash/sortBy';
import _intersectionBy from 'lodash/intersectionBy';
import _concat from 'lodash/concat';
import _isInteger from 'lodash/isInteger';
import dateTimeFunctions from '../mixins/dateTimeFunctions';
import currencyFormatters from '@/mixins/currencyFormatters';

import Imgix from "../services/Imgix";
import ScreenIcon from '@/graphics/icons/ScreenIcon';
import reepayPaymentMixin from '@/components/ReepayPaymentMixin.vue';
import stripePaymentMixin from '@/components/StripePaymentMixin.vue';
import Modal from '@/components/Modal2.vue';

export default {
  components: {
    Modal,
    loadingspinner: LoadingSpinner,
    YogoShowLessWrapper,
    ScreenIcon,
  },
  mixins: [Imgix, dateTimeFunctions, currencyFormatters, reepayPaymentMixin, stripePaymentMixin],
  data() {
    return {
      membershipTypes: [],
      classPassTypes: [],
      classSeriesTypes: [],

      customerMembershipTypeIds: [],
      customerClassPassTypeIds: [],

      customerMemberships: [],
      customerClassPasses: [],
      customerClassSeries: [],

      loading: false,
      selectedPaymentOption: {},

      priceGroups: [],
      selectedPriceGroup: null,

      showAlertDialog: false,
      alertDialogText: '',
      alertDialogConfirmText: '',

      giftCardPricesImage: null,

      showClassSeriesInstallmentModal: false,
      classSeriesInstallmentModalClassSeries: null,
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userName',
      'profileImageFilename',
      'stateReady',
      'user',
      'client',
      'mobileAppMode',
    ]),
    selectedPriceGroupName() {
      if (!this.selectedPriceGroup || !this.priceGroups.length) return '';
      const priceGroup = _find(this.priceGroups,
          priceGroup => priceGroup.id === this.selectedPriceGroup);
      return priceGroup.name;
    },
    visiblePriceGroups() {
      return _filter(this.priceGroups, priceGroup => priceGroup.show_in_default_price_list);
    },
    filteredMembershipTypes() {
      if (!this.priceGroups.length) return this.membershipTypes;
      if (!this.selectedPriceGroup) return [];
      return _filter(this.membershipTypes,
          membershipType => _includes(_map(membershipType.price_groups, 'id'),
              this.selectedPriceGroup));
    },
    filteredClassPassTypes() {
      if (!this.priceGroups.length) return this.classPassTypes;
      if (!this.selectedPriceGroup) return [];
      return _filter(this.classPassTypes,
          classPassType => _includes(_map(classPassType.price_groups, 'id'),
              this.selectedPriceGroup));
    },
    filteredClassSeriesTypes() {
      return _filter(this.classSeriesTypes,
          classSeriesType => _includes(_map(classSeriesType.price_groups, 'id'),
              this.selectedPriceGroup));
    },

    showGiftCard() {
      const showGiftCardInPriceGroups = _map(this.client.settings.gift_card_show_in_price_groups,
          'price_group_id');
      return this.client.settings.gift_card_show_in_prices
          && (
              !this.priceGroups.length
              ||
              _includes(showGiftCardInPriceGroups, this.selectedPriceGroup)
          );
    },
    sortedPriceItems() {
      if (!this.priceGroups.length) {
        return this.sortedPriceItemsWithoutPriceGroups;
      }
      const priceGroup = _find(this.priceGroups,
          pg => parseInt(pg.id) === parseInt(this.selectedPriceGroup));
      const membershipTypes = _map(this.filteredMembershipTypes, mt => {
        mt.sort_in_price_group = _find(priceGroup.membership_types,
            { id: mt.id }).sort_in_price_group;
        mt.priceItemType = 'membership_type';
        return mt;
      });
      const classPassTypes = _map(this.filteredClassPassTypes, cpt => {
        cpt.sort_in_price_group = _find(priceGroup.class_pass_types,
            { id: cpt.id }).sort_in_price_group;
        cpt.priceItemType = 'class_pass_type';
        return cpt;
      });
      const classSeriesTypes = _map(this.filteredClassSeriesTypes, cst => {
        cst.sort_in_price_group = _find(priceGroup.class_series_types,
            { id: cst.id })?.sort_in_price_group;
        // Remove question ^ mark when api is deployed and class_series_types is actually populated
        cst.priceItemType = 'class_series_type';
        return cst;
      });
      const unsortedPrices = _concat(
          membershipTypes,
          classPassTypes,
          classSeriesTypes,
      );
      const giftCardSettings = this.client.settings.gift_card_show_in_price_groups;
      if (_isInteger(giftCardSettings[0])) {
        if (giftCardSettings.indexOf(this.selectedPriceGroup) > -1) {
          unsortedPrices.push({ priceItemType: 'gift_card', sort_in_price_group: 0 });
        }
      } else {
        const giftCardSetting = _find(giftCardSettings,
            o => parseInt(o.price_group_id) === parseInt(this.selectedPriceGroup));
        if (giftCardSetting) {
          unsortedPrices.push({
            priceItemType: 'gift_card',
            sort_in_price_group: giftCardSetting.sort,
          });
        }
      }

      return _sortBy(unsortedPrices, 'sort_in_price_group');
    },

    sortedPriceItemsWithoutPriceGroups() {
      const membershipTypes = _map(this.filteredMembershipTypes, mt => {
        mt.priceItemType = 'membership_type';
        return mt;
      });
      const classPassTypes = _map(this.filteredClassPassTypes, cpt => {
        cpt.priceItemType = 'class_pass_type';
        return cpt;
      });
      const classSeriesTypes = _map(this.filteredClassSeriesTypes, cst => {
        cst.priceItemType = 'class_series_type';
        return cst;
      });
      const sortedPriceItems = _concat(
          membershipTypes,
          classPassTypes,
          classSeriesTypes,
      );
      if (this.client.settings.gift_card_show_in_prices) {
        sortedPriceItems.unshift({
          priceItemType: 'gift_card',
        });
      }
      return sortedPriceItems;
    },
    customerCanPurchaseMembershipsInGeneral() {
      const customerCanPurchaseMultipleMemberships = this.client.settings.customer_can_purchase_multiple_memberships;
      const customerHasAMembership = !!this.customerMemberships
          .filter(m => ['active', 'cancelled_running'].includes(m.status))
          .length;
      return !customerHasAMembership || customerCanPurchaseMultipleMemberships;
    },

    classSeriesModalInstallmentRows() {
      // If no class series type is selected, return empty array
      if (!this.classSeriesInstallmentModalClassSeries) return [];

      // First payment (now)
      const rows = [{
        date: this.$t('global.Now'),
        amount: this.formatCurrency(this.classSeriesInstallmentModalClassSeries.current_required_paid_amount),
        has_member_discount: this.classSeriesInstallmentModalClassSeries.has_member_discount,
        amount_with_member_discount: this.classSeriesInstallmentModalClassSeries.has_member_discount
            ? this.formatCurrency(this.classSeriesInstallmentModalClassSeries.current_required_paid_amount_with_member_discount)
            : null,
      }];
      if (this.classSeriesInstallmentModalClassSeries.current_required_number_of_paid_installments > 1) {
        const n = this.classSeriesInstallmentModalClassSeries.current_required_number_of_paid_installments;
        rows[0].date += ` (${this.$t('event.installmentsOneToN', { n })})`;
      }

      // Future payments
      for (
          let i = this.classSeriesInstallmentModalClassSeries.installments.length - this.classSeriesInstallmentModalClassSeries.number_of_installments_left;
          i < this.classSeriesInstallmentModalClassSeries.installments.length;
          i++
      ) {
        rows.push({
          date: this.formatDate(this.classSeriesInstallmentModalClassSeries.installments[i].date),
          amount: this.formatCurrency(this.classSeriesInstallmentModalClassSeries.installments[i].amount),
          has_member_discount: this.classSeriesInstallmentModalClassSeries.installments[i].has_member_discount,
          amount_with_member_discount: this.classSeriesInstallmentModalClassSeries.installments[i].has_member_discount
              ? this.formatCurrency(this.classSeriesInstallmentModalClassSeries.installments[i].amount_with_member_discount)
              : null,
        });
      }
      return rows;
    },
  },
  mounted: function () {
    if (this.stateReady) this.fetchMembershipsAndClassPasses();
  },
  watch: {
    async stateReady(newReadyState) {
      if (newReadyState) this.fetchMembershipsAndClassPasses();
    },
  },
  methods: {
    async fetchMembershipsAndClassPasses() {

      this.loading = true;

      let membershipTypes, classPassTypes, user;

      [membershipTypes, classPassTypes, user, this.priceGroups, this.classSeriesTypes] = await Promise.all(
          [
            YogoApi.get('/membership-types' +
                '?populate[]=image' +
                '&populate[]=payment_options' +
                '&populate[]=price_groups' +
                '&populate[]=membershipCount' +
                '&populate[]=active_campaign' +
                '&populate[]=userIsEligibleForCampaign' +
                '&populate[]=payment_options.nameThatUserSees' +
                '&populate[]=payment_options.priceTextThatUserSees',
            ),
            YogoApi.get('/class-pass-types' +
                '?populate[]=image' +
                '&populate[]=price_groups' +
                '&populate[]=price_with_member_discount',
            ),
            YogoApi.get('/users/' + this.user.id +
                '?populate[]=memberships' +
                '&populate[]=class_passes',
            ),
            YogoApi.get('/price-groups' +
                '?populate[]=membership_types' +
                '&populate[]=class_pass_types' +
                '&populate[]=class_series_types',
            ),
            YogoApi.get('/class-series-types' +
                '?populate[]=price_groups' +
                '&populate[]=image' +
                '&populate[]=number_of_remaining_classes_and_livestream_classes' +
                '&populate[]=current_price' +
                '&populate[]=first_class' +
                '&populate[]=next_class' +
                '&populate[]=is_available_for_purchase' +
                '&populate[]=fully_booked_remaining_classes' +
                '&populate[]=customer_has_class_series_type' +
                '&populate[]=customer_can_buy_class_series_type' +
                '&populate[]=has_started' +
                '&populate[]=classes_and_classes_livestream' +
                '&populate[]=price_with_member_discount' +
                '&populate[]=current_price_with_member_discount' +
                '&populate[]=number_of_installments_left' +
                '&populate[]=current_required_paid_amount' +
                '&populate[]=current_required_paid_amount_with_member_discount' +
                '&populate[]=installments.amount_with_member_discount' +
                '&excludeEnded=1',
            ),
          ]);

      this.membershipTypes = _keyBy(membershipTypes, 'id');

      this.classPassTypes = _keyBy(classPassTypes, 'id');

      this.classSeriesTypes = _sortBy(
          this.classSeriesTypes,
          ['first_class.date', 'first_class.start_time', 'price'],
      );
      _each(
          this.classSeriesTypes,
          (classSeriesType) => {
            _each(classSeriesType.classes_livestream, (livestreamClass) => {
              livestreamClass.isLivestreamClass = true;
            });
            classSeriesType.displayClasses = _sortBy(
                _concat(classSeriesType.classes, classSeriesType.classes_livestream),
                ['date', 'start_time'],
            );
          },
      );

      this.customerMemberships = user.memberships;
      this.customerClassPasses = user.class_passes;

      this.customerMembershipTypeIds = user.memberships.filter(
          membership => membership.status !== 'ended',
      ).map(
          membership => parseInt(_isObject(membership.membership_type) ? membership.membership_type.id : membership.membership_type),
      );
      this.customerClassPassTypeIds = _map(user.class_passes, (class_pass) => {
        return parseInt(_isObject(class_pass.class_pass_type) ? class_pass.class_pass_type.id : class_pass.class_pass_type);
      });

      this.membershipTypes = _filter(
          this.membershipTypes,
          mt => _find(mt.payment_options, 'for_sale'),
      );

      _each(this.membershipTypes, (membershipType) => {
        membershipType.payment_options = _filter(membershipType.payment_options,
            paymentOption => paymentOption.for_sale);
        membershipType.payment_options = _sortBy(membershipType.payment_options,
            'number_of_months_payment_covers');
        this.selectedPaymentOption[membershipType.id] = membershipType.payment_options[0].id;
      });

      this.selectedPriceGroup = null;

      if (this.visiblePriceGroups.length > 1) {
        this.selectedPriceGroup = null;

        if (this.$route.params && this.$route.params.desiredItem) {

          const priceGroupsWithDesiredItem = await YogoApi.get('/price-groups?desiredItem=' + this.$route.params.desiredItem);

          if (priceGroupsWithDesiredItem.length) {
            const matchingPriceGroups = _intersectionBy(
                this.visiblePriceGroups,
                priceGroupsWithDesiredItem,
                'id',
            );
            if (matchingPriceGroups.length) {
              this.selectedPriceGroup = matchingPriceGroups[0].id;
            }
          }

        }
      }
      if (!this.selectedPriceGroup && this.visiblePriceGroups.length) {
        this.selectedPriceGroup = this.visiblePriceGroups[0].id;
      }


      if (this.client.settings.gift_card_show_in_prices && this.client.settings.gift_card_prices_image_id) {
        this.giftCardPricesImage = await YogoApi.get('/images/' + this.client.settings.gift_card_prices_image_id);
      }

      this.loading = false;

    },

    async buyMembershipType(membershipType) {
      if (!this.customerCanPurchaseMembershipsInGeneral) {
        this.alertDialogText = this.$t('prices.YouAlreadyHaveAMembership');
        this.alertDialogConfirmText = 'Ok';
        this.showAlertDialog = true;
        return;
      }
      const paymentOption = _find(membershipType.payment_options,
          ['id', this.selectedPaymentOption[membershipType.id]]);

      if (paymentOption.payment_amount === 0 && this.client.settings.memberships_allow_no_payment_method) {
        if (!window.confirm(this.$t('prices.ConfirmFreeMembership',
            { membershipName: membershipType.name }))) {
          return;
        }
        this.processFreeItem('membership_type', membershipType.id, paymentOption.id);
        return;
      }

      this.addToCart({
        item_type: 'membership_type',
        item_id: membershipType.id,
        user: this.user.id,
        payment_option: paymentOption.id,
        membership_campaign: (parseInt(paymentOption.number_of_months_payment_covers) === 1 &&
            membershipType.userIsEligibleForCampaign &&
            membershipType.active_campaign_id) ?
            membershipType.active_campaign_id :
            undefined,
      });
    },

    canBuyClassPassType(classPassType, existingClassPasses) {
      return !this.limitedNumberPerCustomerUsedUp(classPassType, existingClassPasses);
    },

    limitedNumberPerCustomerUsedUp(classPassType, existingClassPasses) {
      if (!classPassType.limited_number_per_customer) return false;
      const numberOfExistingClassPassesWithClassPassType = _filter(existingClassPasses,
          classPass => classPass.class_pass_type == classPassType.id).length;

      return numberOfExistingClassPassesWithClassPassType >= classPassType.max_number_per_customer;
    },

    async buyClassPassType(classPassType) {
      if (parseInt(classPassType.price) === 0) {
        if (!window.confirm(this.$t('prices.ConfirmFreeClassPass',
            { classPassName: classPassType.name }))) {
          return;
        }
        this.processFreeItem('class_pass_type', classPassType.id);
        return;
      }

      this.addToCart({
        item_type: 'class_pass_type',
        item_id: classPassType.id,
        user: this.user.id,
      });
    },

    async buyClassSeriesType(classSeriesType, payInInstallments = false) {
      if (parseInt(classSeriesType.price) === 0 && this.client.settings.hide_zero_prices) {
        if (!window.confirm(this.$t('prices.ConfirmFreeClassSeries',
            { classSeriesName: classSeriesType.name }))) {
          return;
        }
        this.processFreeItem('class_series_type', classSeriesType.id);
        return;
      }

      this.addToCart({
        item_type: payInInstallments ? 'class_series_type_first_installment' : 'class_series_type',
        item_id: classSeriesType.id,
        user: this.user.id,
      });
    },

    async processFreeItem(itemType, itemId, paymentOptionId) {
      this.loading = true;
      await YogoApi.post('/cart-items', {
        user: this.user.id,
        item_type: itemType,
        item_id: itemId,
        payment_option: paymentOptionId,
        destroyOthers: true,
      });
      switch (this.client.settings.payment_service_provider) {
        case 'reepay':
          await this.reepayStartPayment();
          break;
        case 'stripe':
          await this.stripeStartPayment();
          break;
      }
    },

    async addToCart(cartItem) {

      this.loading = true;

      await YogoApi.post('/cart-items', cartItem);
      await this.$store.dispatch('updateUser');

      this.$router.push({ name: 'Checkout' });
      this.loading = false;

    },
    customerHasMembershipType(membershipType) {
      return this.customerMembershipTypeIds.indexOf(parseInt(membershipType.id)) > -1;
    },
    getCustomerMembershipsForMembershipType(membershipType) {
      return _filter(
          this.customerMemberships,
          membership => membership.membership_type === membershipType.id && _includes(['active', 'cancelled_running'],
              membership.status),
      );
    },
    customerHasClassPassType(classPassType) {
      return this.customerClassPassTypeIds.indexOf(parseInt(classPassType.id)) > -1;
    },
    getSelectedMembershipTypePaymentOption(membershipType) {
      const paymentOptionId = this.selectedPaymentOption[membershipType.id];
      return _find(membershipType.payment_options,
          paymentOption => paymentOption.id === paymentOptionId);
    },
    async buyGiftCard() {
      this.$router.push({ name: 'BuyGiftCard' });
    },
    showClassSeriesInstallmentPaymentOption(classSeriesType) {
      return classSeriesType.customer_can_buy_class_series_type
          && classSeriesType.installment_payments_enabled
          && classSeriesType.number_of_installments_left >= 1;
    },
    openClassSeriesInstallmentsModal(classSeriesType) {
      this.classSeriesInstallmentModalClassSeries = classSeriesType;
      this.showClassSeriesInstallmentModal = true;
    },
  },
};
</script>

<style lang="stylus">

.modal
  background: white !important;
  padding 20px

</style>
