<template>
  <span>
    <i18n path="checkout.IHaveReadAndAcceptTheTerms" class="select-none">
      <template v-slot:link>
        <a class="underline" href="#" @click.stop.prevent="showTerms = true">
          {{ $t('checkout.IHaveReadAndAcceptTheTermsLinkText') }}</a>
      </template>
    </i18n>*

    <md-dialog :md-active.sync="showTerms">
      <md-dialog-content class="terms-and-conditions">
        <h1>{{ $t('dialog.terms.TermsAndConditions') }}</h1>
        <div v-html="client.settings.terms"></div>
      </md-dialog-content>
      <md-dialog-actions>
        <button class="btn btn-primary" @click="showTerms = false">{{
            $t('dialog.Close')
          }}
        </button>
      </md-dialog-actions>
    </md-dialog>
  </span>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: 'AcceptTermsText',
  data() {
    return {
      showTerms: false,
    };
  },
  computed: {
    ...mapGetters([
      'client',
    ]),
  },
};
</script>
