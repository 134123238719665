<template>

  <loadingspinner v-if="loading"></loadingspinner>
  <div v-else>

    <div class="min-h-screen lg:flex lg:justify-center bg-white lg:bg-transparent pb-8">
      <div class="login narrow-wrapper w-full max-w-xl m-auto wrapper">
        <div class="bg-white md:pl-6 md:pr-6 md:pb-6 pt-4 md:mt-4 lg:mt-8 lg:pb-16">
          <div class="my-6">
            <ModalLogo></ModalLogo>
          </div>

          <h2 class="text-center mb-8">
            {{ $t('login.createProfile') }}
          </h2>

          <div class="flex-column">

            <form novalidate @submit.prevent="submitForm">

              <div v-if="client.settings.signup_show_image_field">
                <div class="bold">{{ $t('signup.labels.ProfileImage') }}<span
                    v-if="client.settings.signup_image_field_required">
                  *
                </span></div>
                <ImageField
                    :imageId.sync="form.image"
                    imageFormat="profile"
                ></ImageField>
              </div>

              <!--  <md-field :class="getValidationClass('first_name')">
                 <label>{{ $t('login.firstNameLabel') }}</label>
                 <md-input required type="text" v-model="form.first_name" autocomplete="given-name"></md-input>
                 <span class="md-error" v-if="!$v.form.first_name.required">{{ $t('login.firstNameMust') }}</span>
               </md-field> -->

              <InputField
                  :class="getValidationClass('first_name')"
                  :label="$t('login.firstNameLabel')"
                  type="text"
                  v-model="form.first_name"
                  :required="true"
                  autocomplete="given-name"
              >
                <template v-slot:errors>
                  <span class="error" v-if="!$v.form.first_name.required">{{
                      $t('login.firstNameMust')
                    }}</span>
                </template>
              </InputField>

              <InputField
                  :class="getValidationClass('last_name')"
                  :label="$t('login.lastNameLabel')"
                  type="text"
                  v-model="form.last_name"
                  :required="true"
                  autocomplete="family-name"
              >
                <template v-slot:errors>
                  <span class="error" v-if="!$v.form.last_name.required">{{
                      $t('login.lastNameMust')
                    }}</span>
                </template>
              </InputField>


              <InputField
                  :label="$t('login.emailLabel')"
                  :class="getValidationClass('email')"
                  type="email"
                  :required="true"
                  autocomplete="email"
                  v-model="form.email"
              >
                <template v-slot:errors>
                  <span class="error" v-if="!$v.form.email.required">{{
                      $t('login.typeEmail')
                    }}</span>
                  <span class="error" v-else-if="!$v.form.email.email">{{
                      $t('login.emailNotValid')
                    }}</span>
                </template>
              </InputField>

              <!--<InputField
                :label="$t('login.birthdayLabel')"
                :class="getValidationClass('date_of_birth')"
                :required="client.settings.signup_date_of_birth_field_required"
                v-model="form.date_of_birth"
                type="date"
                v-if="client.settings.signup_show_date_of_birth_field"
              >
              <template v-slot:errors>
               <span class="error" v-if="client.settings.signup_date_of_birth_field_required && !$v.form.date_of_birth.required">{{ $t('login.birthdayMust') }}</span>
              </template>
            </InputField>-->

              <BirthdaySelect
                  v-model="form.date_of_birth"
                  :class="getValidationClass('date_of_birth')"
                  v-if="client.settings.signup_show_date_of_birth_field"
                  :required="client.settings.signup_date_of_birth_field_required"
              >
                <template v-slot:errors>
                  <span class="error mt-2"
                        v-if="$v.form.date_of_birth && !$v.form.date_of_birth.required">{{
                      $t('login.birthdayMust')
                    }}</span>
                </template>
              </BirthdaySelect>

              <InputField
                  :label="$t('login.phoneLabel')"
                  :class="getValidationClass('phone')"
                  type="tel"
                  pattern="^\+?[\d -]+$"
                  autocomplete="tel"
                  v-model="form.phone"
                  v-if="client.settings.signup_show_phone_field"
                  :required="client.settings.signup_phone_field_required"
              >
                <template v-slot:errors>
                  <span class="error"
                        v-if="client.settings.signup_phone_field_required && !$v.form.phone.required">{{
                      $t('login.phoneMust')
                    }}</span>
                </template>
              </InputField>

              <InputField
                  v-if="client.settings.signup_show_address_fields"
                  :class="getValidationClass('address_1')"
                  :label="$t('login.addressLabel')"
                  type="text"
                  v-model="form.address_1"
                  :required="client.settings.signup_address_fields_required"
                  autocomplete="street-address"
              >
                <template v-slot:errors>
                  <span class="error"
                        v-if="client.settings.signup_address_fields_required && !$v.form.address_1.required">{{
                      $t('login.addressMust')
                    }}</span>
                </template>
              </InputField>

              <InputField
                  v-if="client.settings.signup_show_address_fields"
                  :class="getValidationClass('zip_code')"
                  :label="$t('login.zipLabel')"
                  type="text"
                  v-model="form.zip_code"
                  :required="client.settings.signup_address_fields_required"
                  autocomplete="postal-code"
              >
                <template v-slot:errors>
                  <span class="error"
                        v-if="client.settings.signup_address_fields_required && !$v.form.zip_code.required">{{
                      $t('login.zipMust')
                    }}</span>
                </template>
              </InputField>

              <InputField
                  v-if="client.settings.signup_show_address_fields"
                  :class="getValidationClass('city')"
                  :label="$t('login.cityLabel')"
                  type="text"
                  v-model="form.city"
                  :required="client.settings.signup_address_fields_required"
                  autocomplete="address-level2"
              >
                <template v-slot:errors>
                  <span class="error"
                        v-if="client.settings.signup_address_fields_required && !$v.form.city.required">{{
                      $t('login.cityMust')
                    }}</span>
                </template>
              </InputField>

              <div v-if="client.settings.signup_show_address_fields" class="yogo-input mb-6">
                <label>{{ $t('login.countryLabel') }}<span
                    v-if="client.settings.signup_address_fields_required">*</span></label>
                <country-select v-model="form.country"></country-select>
              </div>

              <input-field
                  type="select"
                  :label="client.settings.customer_branch_field_label"
                  v-model="form.branch"
                  :required="client.settings.signup_branch_field_required"
                  v-if="client.settings.signup_show_branch_field"
                  :class="getValidationClass('branches')"
              >
                <template v-slot:options>
                  <option v-if="!client.settings.signup_branch_field_required" value=""></option>
                  <option
                      v-for="branch in branchSelectData" :value="branch.id"
                      :key="'branch' + branch.id"
                  >
                    {{ branch.name }}
                  </option>
                </template>
              </input-field>


              <InputField
                  v-if="client.settings.signup_show_additional_info_field"
                  :class="getValidationClass('customer_additional_info')"
                  :label="client.settings.customer_profile_additional_info_field_subtitle || $t('signup.AdditionalInfoFieldSubtitle')"
                  type="text"
                  v-model="form.customer_additional_info"
                  :required="client.settings.signup_additional_info_field_required"
                  autocomplete="off"
              >
                <template v-slot:errors>
                  <span class="error"
                        v-if="client.settings.signup_additional_info_field_required && !$v.form.customer_additional_info.required">{{
                      $t('signup.labels.Required')
                    }}</span>
                </template>
              </InputField>


              <InputField
                  :label="$t('login.passwordLabel')"
                  :class="getValidationClass('password')"
                  :required="true"
                  id="password"
                  type="password"
                  v-model="form.password"
                  autocomplete="off"
              >
                <template v-slot:errors>
                  <span class="error" v-if="!$v.form.password.required">{{
                      $t('login.passwordMustBeCreated')
                    }}</span>
                  <span class="error" v-else-if="!$v.form.password.passwordStrength">{{
                      $t('login.passwordDescription')
                    }}</span>
                </template>
              </InputField>

              <label class="checkbox mb-4" v-if="client.settings.signup_show_newsletter_checkbox">
                <input type="checkbox" v-model="form.newsletter">
                <span>
                  {{ client.settings.signup_newsletter_checkbox_label }}
                </span>
              </label>

              <label class="checkbox">
                <input type="checkbox" v-model="acceptTerms">
                <accept-terms-text/>
              </label>

              <label class="checkbox my-4" v-if="client.settings.customer_must_accept_the_privacy_policy">
                <input type="checkbox" v-model="acceptPrivacyPolicy">
                <accept-privacy-policy-text @linkClick="showPrivacyPolicy = true"/>
              </label>

              <label class="checkbox my-4" v-if="client.settings.customer_must_accept_the_consent_form">
                <input type="checkbox" v-model="acceptConsentForm">
                <accept-consent-form-text @linkClick="showConsentForm = true"/>
              </label>

              <div class="space8"></div>

              <button
                  type="submit"
                  class="btn width--100 btn-primary"
                  :class="{ 'btn-primary-app w-full mt-2': mobileAppMode }"
                  :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              >
                {{ $t('login.createProfile') }}
              </button>
              <div class="space4"></div>
              <router-link :to="{name: 'Login'}" v-if="!mobileAppMode">{{
                  $t('login.backToLogin')
                }}
              </router-link>

            </form>
          </div>
        </div>

        <md-dialog :md-active.sync="showUserExists">
          <md-dialog-title>{{ $t('login.emailExcist') }}</md-dialog-title>
          <md-dialog-content>
            <p class="mb-2">{{ $t('login.emailExcistDesc') }} "{{ userExistsDialogEmail }}".</p>
            <p class="mb-2">{{ $t('global.please') }}
              <router-link :to="{ name: 'Login' }" class="whitespace--nowrap underline">{{
                  $t('login.signin')
                }}
              </router-link>
            </p>
            <p>{{ $t('login.ifYouForgotYourPass') }}
              <router-link :to="{ name: 'ResetPassword' }" class="whitespace--nowrap underline">{{
                  $t('login.resetItHere')
                }}
              </router-link>
            </p>

          </md-dialog-content>
          <md-dialog-actions>
            <button
                class="btn btn-primary"
                :class="{ 'btn-small-app': mobileAppMode }"
                @click="showUserExists = false"
            >
              OK
            </button>
          </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-active.sync="showSignupSuccess" @md-closed="signupSuccessDialogClose">
          <md-dialog-title>{{ $t('login.thanksForYourSubmit') }}</md-dialog-title>
          <md-dialog-content>
            <p>{{ $t('login.youCanNowBuyCoursesEtc') }}</p>
            <p v-if="!mobileAppMode">{{ $t('login.youWillBeRedirected') }}</p>
            <p v-if="mobileAppMode">{{ $t('login.mobileAppPleaseNavigateBackToLogin') }}</p>
          </md-dialog-content>
          <md-dialog-actions v-if="!mobileAppMode">
            <button
                class="btn btn-primary"
                :class="{ 'btn-small-app': mobileAppMode }"
                @click="showSignupSuccess = false"
            >
              OK
            </button>
          </md-dialog-actions>
        </md-dialog>

        <forgot-to-accept-terms-alert :show.sync="showForgotToAcceptTermsAlert"/>
        <forgot-to-accept-privacy-policy-alert :show.sync="showForgotToAcceptPrivacyPolicyAlert"/>
        <forgot-to-accept-consent-form-alert :show.sync="showForgotToAcceptConsentFormAlert"/>

      </div>
    </div>
  </div>


</template>

<script>

import YogoApi from '../gateways/YogoApi';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import _sortBy from 'lodash/sortBy';
import InputField from './InputField.vue';
import _pick from 'lodash/pick';
import { email, required } from 'vuelidate/lib/validators';
import Loadingspinner from "./LoadingSpinner";
import ModalLogo from './ModalLogo';

import BirthdaySelect from '@/components/BirthdaySelect';
import semver from 'semver';
import ImageField from '@/components/ImageField.vue';
import CountrySelect from '@/components/CountrySelect.vue';
import AcceptTermsText from '@/components/fragments/AcceptTermsText.vue';
import AcceptConsentFormText from '@/components/fragments/AcceptConsentFormText.vue';
import AcceptPrivacyPolicyText from '@/components/fragments/AcceptPrivacyPolicyText.vue';
import ForgotToAcceptTermsAlert from '@/components/fragments/ForgotToAcceptTermsAlert.vue';
import ForgotToAcceptPrivacyPolicyAlert
  from '@/components/fragments/ForgotToAcceptPrivacyPolicyAlert.vue';
import ForgotToAcceptConsentFormAlert
  from '@/components/fragments/ForgotToAcceptConsentFormAlert.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        image: null,
        country: 'DK',
        password: '',
        branch: '',
        date_of_birth: null,
        newsletter: false,
        customer_additional_info: '',
      },
      branchSelectData: [],
      loading: true,

      showUserExists: false,
      userExistsDialogEmail: '',

      showSignupSuccess: false,

      acceptTerms: false,
      showForgotToAcceptTermsAlert: false,

      acceptPrivacyPolicy: false,
      showForgotToAcceptPrivacyPolicyAlert: false,

      acceptConsentForm: false,
      showForgotToAcceptConsentFormAlert: false,
    };
  },
  components: {
    ForgotToAcceptConsentFormAlert,
    ForgotToAcceptPrivacyPolicyAlert,
    ForgotToAcceptTermsAlert,
    AcceptConsentFormText,
    AcceptPrivacyPolicyText,
    AcceptTermsText,
    CountrySelect,
    ImageField,
    Loadingspinner,
    ModalLogo,
    // Datepicker,
    InputField,
    BirthdaySelect,
    //YogoSelect,
  },
  computed: {
    ...mapGetters([
      'stateReady',
      'client',
      'profileImageFilename',
      'clientLogoFilename',
      'emailConfirmationLink',
      'mobileAppMode',
    ]),
  },
  mounted() {
    if (this.stateReady) {
      this.init();
    }
  },
  watch: {
    stateReady(newReadyState) {
      if (newReadyState) {
        this.init();
      }
    },
  },
  async created() {
    this.branchSelectData = await YogoApi.get('/branches/');
    this.branchSelectData = _sortBy(this.branchSelectData, 'sort');
    if (this.client.settings.signup_branch_field_required) {
      this.form.branch = this.branchSelectData[0].id;
    }
  },
  methods: {
    async init() {
      if (this.client.settings.signup_closed_with_token) {
        const token = this.$route.query.signupToken;
        const tokenIsValid = await YogoApi.post(`/clients/${this.client.id}/check-closed-signup-token`,
            {
              token,
            });
        if (!tokenIsValid) {
          alert('This signup link is not valid. Please contact the studio for more information.');
          this.$router.push({ name: 'Login' });
          return;
        }
      }
      this.form.country = this.client.country;
      this.loading = false;
    },
    async submitForm() {

      if (this.client.settings.signup_show_image_field && this.client.settings.signup_image_field_required && !this.form.image) {
        window.alert(this.$t('signup.YouHaveNotUploadedAProfileImage'));
        return;
      }

      this.showUserExists = false;

      this.$v.$touch();

      if (this.$v.$invalid) return;

      if (!this.acceptTerms) {
        console.log('showForgotToAcceptTermsAlert');
        this.showForgotToAcceptTermsAlert = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_privacy_policy && !this.acceptPrivacyPolicy) {
        this.showForgotToAcceptPrivacyPolicyAlert = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_consent_form && !this.acceptConsentForm) {
        this.showForgotToAcceptConsentFormAlert = true;
        return;
      }


      let userData = _pick(
          this.form, [
            'first_name',
            'last_name',
            'email',
            'password',
            'date_of_birth',
            'phone',
            'address_1',
            'zip_code',
            'city',
            'image',
            'newsletter',
            'customer_additional_info',
          ],
      );

      if (this.client.settings.signup_show_address_fields) {
        // This has a default, and should therefore only be posted if select field is visible
        userData.country = this.form.country;
      }
      if (!!this.client.branches.length && this.client.settings.signup_show_branch_field) {
        const branchId = parseInt(this.form.branch);
        userData.branches = branchId ? [branchId] : [];
      }

      // This is for the welcome email with email confirmation link.
      userData.emailConfirmationLink = this.emailConfirmationLink;

      // If signup is closed, we need to add the signup token
      if (this.client.settings.signup_closed_with_token) {
        userData.signupToken = this.$route.query.signupToken;
      }

      this.loading = true;


      const response = await YogoApi.post('/users', userData);

      if (response === 'E_USER_EXISTS' || response === 'E_EMAIL_EXISTS') {
        this.loading = false;
        this.userExistsDialogEmail = userData.email;
        this.showUserExists = true;
        return;
      }

      window.localStorage.setItem('accessToken', response.token);

      const user = await YogoApi.get('/users/' + response.user.id + '?populate=image');

      await this.$store.dispatch('updateUser', user.id);


      // Mobile app v 2 (Flutter) needs a trigger to read the access token
      // Mobile app v 1 (Nativescript) needs the confirmation popup, telling the user to navigate back
      let isMobileApp2 = false;
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('mobileAppMode')) {
        const mobileAppVersion = urlParams.get('mobileAppVersion');
        if (mobileAppVersion && semver.gte(mobileAppVersion, '2.0.0')) {
          isMobileApp2 = true;
        }
      }

      if (isMobileApp2) {
        urlParams.set('signupSuccess', 1);
        window.location.search = urlParams;
      } else {
        this.loading = false;
        this.showSignupSuccess = true;
      }

    },

    signupSuccessDialogClose() {
      if (this.mobileAppMode) return;
      this.$router.push({ name: 'Prices' });
    },

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          'invalid': field.$invalid && field.$dirty,
        };
      }
    },
  },
  validations() {
    let v = {
      form: {
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          passwordStrength(value) {
            const length = value.length >= 6;
            if (!length) return false;
            const upperCase = !!value.match(/[A-Z]+/);
            const lowerCase = !!value.match(/[a-z]+/);
            const numbers = !!value.match(/[0-9]+/);
            return upperCase && lowerCase && numbers;
          },
        },
      },
    };
    if (this.client.settings.signup_show_date_of_birth_field && this.client.settings.signup_date_of_birth_field_required) {
      v.form.date_of_birth = {
        required,
      };
    }
    if (this.client.settings.signup_show_phone_field && this.client.settings.signup_phone_field_required) {
      v.form.phone = {
        required,
      };
    }
    if (this.client.settings.signup_show_address_fields && this.client.settings.signup_address_fields_required) {
      v.form.address_1 = {
        required,
      };
      v.form.zip_code = {
        required,
      };
      v.form.city = {
        required,
      };
    }
    if (this.client.settings.signup_show_additional_info_field && this.client.settings.signup_additional_info_field_required) {
      v.form.customer_additional_info = {
        required,
      };
    }
    return v;
  },
};
</script>

<style lang="stylus">

.client-logo
  width: 120px
  height: 120px
  display: block;
  margin: 20px auto

</style>
