<template>
  <div :class="{ 'wrapper' : !mobileAppMode }">

    <div class="theme--frame-box">

      <loadingspinner v-if="loading" :text="loadingText"></loadingspinner>

      <div v-else>

        <router-link v-if="mobileAppMode" :to="{ name: 'MyProfile' }" class="flex aic">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
               fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
               stroke-linejoin="round" class="feather feather-arrow-left">
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
          <span class="pl-1">
          {{ $t('global.back') }}
        </span>
        </router-link>

        <h4 class="mt-4 text-lg">{{ $t('membership.membership') }}:</h4>
        <h1>
          {{ membershipTitle }}
          <span v-if="membership.current_membership_pause">
            ({{ $t('membership.Paused') }}
            {{ formatDate(membership.current_membership_pause.start_date) }}
            - {{
              membership.current_membership_pause.end_date ? formatDate(membership.current_membership_pause.end_date) : '?'
            }})
          </span>
        </h1>

        <h3 v-if="membership.start_date > todayIso">
          {{ $t('membership.StartsOnDate', { date: formatDate(membership.start_date) }) }}
        </h3>

        <h3 v-if="membership.discount_code">
          {{ $t('checkout.discountCode') }}:
          {{ membership.discount_code.name }}<span
            v-if="membership.discount_code_applies_to_limited_number_of_payments">,
          {{
            $t('discountCode.NMonthsRemaining',
                { months: formatNumber(membership.discount_code_number_of_reduced_payments_left) })
          }}
        </span>
        </h3>

        <div class="space8"></div>
        <div class="space8"></div>

        <div class="flex__1-2" v-if="membership.upcoming_membership_pause">
          <h2>{{ $t('membership.ScheduledMembershipPause') }}</h2>
          <md-table>
            <md-table-row>
              <md-table-head>{{ $t('global.Start') }}</md-table-head>
              <md-table-head>{{ $t('global.End') }}</md-table-head>
              <!--<md-table-head>&nbsp;</md-table-head>-->
            </md-table-row>
            <md-table-row>
              <md-table-cell>
                {{ formatDate(membership.upcoming_membership_pause.start_date) }}
              </md-table-cell>
              <md-table-cell>
                {{
                  membership.upcoming_membership_pause.end_date ? formatDate(membership.upcoming_membership_pause.end_date) : '--'
                }}
              </md-table-cell>
              <!--<md-table-cell>
                <button class="btn btn-primary" @click="cancelUpcomingMembershipPause">{{ $t('membership.Cancel__MembershipPause') }}</button>
              </md-table-cell>-->
            </md-table-row>
          </md-table>
          <div class="space8"></div>
        </div>

        <div class="flex--row space--between align__items--start">

          <div class="flex__1-2" v-if="membership.next_payment">
            <h2>{{ $t('global.nextPayment') }}</h2>
            <md-table>
              <md-table-row>
                <md-table-head>{{ $t('global.date') }}</md-table-head>
                <md-table-head>{{ $t('global.amount') }}</md-table-head>
              </md-table-row>
              <md-table-row>
                <md-table-cell>
                  <div v-if="membership.next_payment.payment_for_multiple_periods">
                    {{ formatDate(membership.next_payment.payment_periods[0].start_date) }}
                    - {{ formatDate(membership.next_payment.payment_periods[0].end_date) }}
                    {{ $t('global.and') }}
                    {{ formatDate(membership.next_payment.payment_periods[1].start_date) }}
                    - {{ formatDate(membership.next_payment.payment_periods[1].end_date) }}
                  </div>
                  <span v-else>
                    {{
                      membership.next_payment.date ? formatDate(membership.next_payment.date) : '--'
                    }}
                    <span
                        v-if="membership.next_payment.payment_is_for_partial_month || membership.next_payment.payment_period_reduced_because_of_membership_pause"> -
                      {{ formatDate(membership.next_payment.payment_period_end_date) }}
                    </span>
                  </span>
                </md-table-cell>
                <md-table-cell>{{ formatCurrency(membership.next_payment.amount) }}
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>

          <div class="flex__1-2" v-if="membership.status === 'cancelled_running'">
            <p>{{ $t('membership.membershipCancelledAndStopsBy') }}
              {{ formatDate(membership.cancelled_from_date) }} </p>
            <button
                type="button"
                class="btn btn-primary"
                :class="{ 'btn-primary-app w-full mt-2': mobileAppMode }"
                :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
                @click="resumeMembershipClick"
                v-if="client.id !== 470"
            >
              {{ $t('membership.resubmitMembership') }}
            </button>
          </div>

          <div class="flex__1-2" v-if="membership.status === 'active'">
            <h2>{{ $t('membership.paymentPeriod') }}</h2>
            <br/>
            <p class="bold">{{ membership.payment_option.name }}</p>
            <p v-if="membership.next_payment.payment_is_for_partial_month">
              {{ $t('membership.PartialMonthPayment') }}
            </p>
          </div>

        </div>

        <div class="space8"></div>

        <div v-if="membership.pending_no_show_fees.length">
          <h2>{{ $t('global.PendingNoShowFees') }}</h2>
          <div v-if="membership.next_payment" class="mt-2">
            {{ $t('global.FeesWillBeChargedWithNextPayment') }}
          </div>
          <md-table v-model="membership.pending_no_show_fees">
            <md-table-row slot="md-table-row" slot-scope="{ item }"
                          :key="'pending_no_show_fee_'+ item.id">
              <md-table-cell :md-label="$t('global.Date')">{{
                  formatDate(item.class.date)
                }}
              </md-table-cell>
              <md-table-cell :md-label="$t('global.Time')">{{
                  item.class.start_time.substr(0, 5)
                }}
              </md-table-cell>
              <md-table-cell :md-label="$t('global.Class')">{{ item.class.class_type.name }}
              </md-table-cell>
              <md-table-cell :md-label="$t('global.Reason')">
                {{ $t('global.NoShowReason_' + item.reason) }}
              </md-table-cell>
              <md-table-cell :md-label="$t('global.Amount')" md-numeric>
                {{ formatCurrency(item.amount) }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div class="space8"></div>

        <div class="" v-if="membership.next_payment">
          <h2>{{ $t('profile.paymentInfo') }}</h2>
          <h3 v-if="membership.renewal_failed" class="auto-renewal-failed red">
            {{ $t('membership.autoRenewalFailed') }}
          </h3>
          <md-field v-if="activeSubscription">
            <div class="flex--row space--between">
              {{
                getPayType(activeSubscription.pay_type)
              }}: {{
                activeSubscription.card_prefix
                    ? activeSubscription.card_prefix.substring(0,
                    4) + ' ' + activeSubscription.card_prefix.substring(4, 6)
                    : ''
              }}XX
              XXXX {{
                activeSubscription.card_last_4_digits
              }}
            </div>
          </md-field>
          <p v-else>
            {{ $t('membership.noCreditCardAttached') }}
          </p>
          <button
              class="btn btn-primary margin__bottom--10 mt-2"
              :class="{ 'btn-primary-app w-full': mobileAppMode }"
              :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              @click="startChangeCreditCard"
          >
            {{
              activeSubscription ? (membership.renewal_failed ? $t(
                      'profile.editCreditCardAndRetryPaymentLabel') : $t('profile.editCreditCardLabel'))
                  : $t('profile.addCreditCardLabel')
            }}
          </button>
          <button
              class="btn btn-primary margin__bottom--10"
              :class="{ 'btn-primary-app w-full': mobileAppMode }"
              v-if="membership.renewal_failed"
              @click="retryPaymentWithCurrentPaymentMethod"
          >
            {{ $t('profile.retryPaymentWithCurrentPaymentMethodLabel') }}
          </button>
        </div>

        <div class="space8"></div>

        <div v-if="membership.orders.length">

          <h2>{{ $t('membership.previousPayments') }}</h2>
          <md-table v-model="membership.orders">
            <md-table-row slot="md-table-row" slot-scope="{ item }" :key="item.id">
              <md-table-cell :md-label="$t('global.invoice')">{{ item.invoice_id }}</md-table-cell>
              <md-table-cell :md-label="$t('global.Date')">{{
                  formatDate(item.createdAt)
                }}
              </md-table-cell>
              <md-table-cell :md-label="$t('global.Amount')" md-numeric>{{ formatCurrency(item.total) }}
              </md-table-cell>
              <md-table-cell :md-label="$t('global.Status')">{{ getOrderStatusText(item) }}</md-table-cell>
              <md-table-cell>
                <a href="#" v-if="item.payment_failed">{{ $t('global.payNow') }}</a>
                <a :href="getReceiptLink(item)" v-else-if="item.paid" target="_blank">{{
                    $t('membership.downloadReceipt')
                  }}</a>
              </md-table-cell>
            </md-table-row>
          </md-table>

        </div>

        <div class="line"></div>

        <div class="space8"></div>

        <button
            type="button"
            class="btn btn-primary"
            :class="{ 'btn-secondary-app': mobileAppMode }"
            @click="stopMembershipClick"
            v-if="membership.status === 'active' && client.settings.customer_can_cancel_membership">
          {{ $t('membership.StopMembership') }}
        </button>

        <!--<button
            type="button"
            class="btn btn-primary"
            @click="pauseMembershipClick"
            v-if="showPauseMembershipButton">
          {{ $t('membership.PauseMembership') }}
        </button>-->

      </div>
    </div>

    <div v-if="!mobileAppMode">
      <div class="space8"></div>
      <div class="space8"></div>
      <div class="space8"></div>
    </div>

    <Alert :show.sync="showAlertDialog" :alert-text="alertDialogText"/>

    <Modal2 :show.sync="showStopMembershipWarningDialog">
      <template v-slot:title>{{ $t('membership.StopMembership?') }}</template>
      <template v-slot:content>
        {{ membership.customer_cancellation_warning }}
      </template>
      <template v-slot:actions>
        <button
            class="btn btn-primary"
            :class="{ 'btn-secondary-app w-full': mobileAppMode }"
            @click.prevent="stopMembership"
        >
          {{ $t('membership.StopMembership') }}
        </button>
      </template>
    </Modal2>

    <md-snackbar :md-duration="4000" :md-active.sync="showSnackbar">
      {{ snackbarText }}
    </md-snackbar>

  </div>
</template>

<script>

import Loadingspinner from "./LoadingSpinner.vue";
import YogoApi from '../gateways/YogoApi';
import { mapGetters } from 'vuex';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import avatar from '@/assets/img/account.svg';
import dateTimeFunctions from '../mixins/dateTimeFunctions';
import Alert from '@/components/Alert';
import qs from 'qs';
import Modal2 from '@/components/Modal2';
import currencyFormatters from '@/mixins/currencyFormatters';
import numberFormatters from '@/mixins/numberFormatters';

export default {
  name: 'membership',
  components: {
    Modal2,
    Alert,
    Loadingspinner,
  },
  mixins: [dateTimeFunctions, currencyFormatters, numberFormatters],
  data() {
    return {
      todayIso: null,

      loading: true,
      loadingText: '',

      membership: {},
      selectedPaymentOption: null,

      showAlertDialog: false,
      alertDialogText: '',

      reepayWindow: null,

      snackbarText: '',
      showSnackbar: false,

      showStopMembershipWarningDialog: false,

    };
  },
  computed: {
    ...mapGetters([
      'client',
      'user',
      'cartItems',
      'stateReady',
      'apiRoot',
      'membershipFlashMessage',
      'mobileAppMode',
    ]),
    activeSubscription() {
      if (!this.membership) return null;

      let activePaymentSubscriptions = _filter(this.membership.payment_subscriptions,
          paymentSubscription => paymentSubscription.status === 'active');

      if (!activePaymentSubscriptions.length) {
        return null;
      }

      if (!activePaymentSubscriptions.length > 1) {
        throw Error('Too many active subscription');
      }

      return activePaymentSubscriptions[0];
    },
    currentOrUpcomingMembershipPause() {
      return this.membership.current_membership_pause
          || this.membership.upcoming_membership_pause;
    },
    showPauseMembershipButton() {
      return this.membership
          && this.membership.status === 'active'
          && this.client.settings.customer_can_pause_membership
          && !this.currentOrUpcomingMembershipPause;
    },
    membershipTitle() {
      let title = this.membership.membership_type.name;
      if (this.membership.membership_campaign && this.membership.membership_campaign_number_of_reduced_payments_left > 0) {
        title += ', ' + this.membership.membership_campaign.name;
      }
      return title;
    },
  },
  mounted() {
    if (this.stateReady) this.getMembership();
    if (this.membershipFlashMessage) {
      this.alertDialogText = {
        updated: this.$t('membership.PaymentCardUpdated'),
        failed: this.$t('membership.paymentUpdateCancelled'),
      }[this.membershipFlashMessage.type];
      this.showAlertDialog = true;
      this.$store.commit('setMembershipFlashMessage', null);
    }
  },
  methods: {
    async getMembership() {

      this.todayIso = DateTime.now().setZone(this.client.timezone).toISODate();

      const queryString = qs.stringify({
        id: this.$route.params.id,
        populate: [
          'payment_option',
          'membership_type',
          'membership_type.payment_options',
          'payment_subscriptions',
          'orders',
          'membership_campaign',
          'discount_code',
          'next_payment',
          'pending_no_show_fees',
          'pending_no_show_fees.class',
          'pending_no_show_fees.class.class_type',
          'current_membership_pause',
          'upcoming_membership_pause',
          'customer_can_pause_membership',
          'customer_cancellation_warning',
        ],
      });

      this.membership = await YogoApi.get(`/memberships?${queryString}`);

      if (this.membership.payment_option) {
        this.selectedPaymentOption = this.membership.payment_option.id;
      }

      this.membership.orders = _sortBy(this.membership.orders, order => -order.invoice_id);

      this.loading = false;


    },
    getPayType(payType) {

      const payTypes = {
        'V-DK': 'Visa/Dankort',
        'MC': 'Eurocard/Mastercard',
        'MC(DK)': 'Eurocard/Mastercard (DK)',
        'MC(SE)': 'Eurocard/Mastercard (SE)',
        'DIN': 'Diners Club',
        'DIN(DK)': 'Diners Club (DK)',
        'AMEX': 'American Express',
        'AMEX(DK)': 'American Express (DK)',
        'MTRO': 'Maestro',
        'MTRO(DK)': 'Maestro(DK)',
        'ELEC': 'VISA Electron',
        'FFK': 'Forbrugsforeningen',
      };
      if (payTypes[payType]) return payTypes[payType];

      return payType;

    },

    getOrderStatusText(order) {
      if (order.payment_failed) {
        return this.$t('global.declined');
      } else if (order.paid) {
        return this.$t('global.paid');
      } else {
        return this.$t('global.awaitingPayment');
      }
    },

    async stopMembershipClick() {
      if (this.membership.current_membership_pause) {
        alert(this.$t('membership.YourMembershipIsAlreadyPausedPleaseContactTheStudio'));
        return;
      }
      if (this.membership.upcoming_membership_pause) {
        alert(this.$t('membership.ThereIsAlreadyAScheduledMembershipPausePleaseContactTheStudio'));
        return;
      }

      this.showStopMembershipWarningDialog = true;

    },
    async stopMembership() {
      this.showStopMembershipWarningDialog = false;
      this.loading = true;
      await YogoApi.put('/memberships/' + this.$route.params.id, {
        status: 'cancelled_running',
      });
      this.getMembership();
    },
    async resumeMembershipClick() {
      if (!confirm(this.$t('membership.wouldYouLikeToResumeTheMembership'))) return;
      this.loading = true;
      await YogoApi.put('/memberships/' + this.$route.params.id, {
        status: 'active',
      });
      this.getMembership();
    },
    async pauseMembershipClick() {
      if (!this.membership.customer_can_pause_membership) {
        this.alertDialogText = this.$t(
            'membership.YouCanOnlyPauseYourMembershipNTimesInAYear',
            { times: this.client.settings.membership_pause_max_count_per_running_year },
        );
        this.showAlertDialog = true;
      }
    },
    async startChangeCreditCard() {
      switch (this.client.settings.payment_service_provider) {
        case 'reepay': {
          const recurringSession = await YogoApi.post(
              '/payments/reepay/create-recurring-session');
          this.reepayWindow = new window.Reepay.ModalCheckout(recurringSession.id);

          this.reepayWindow.addEventHandler(window.Reepay.Event.Accept, async data => {
            console.log('data:', data);
            await YogoApi.post('/payments/reepay/attach-card-to-membership', {
              cardId: data.payment_method,
              membership: this.membership.id,
            });
            if (this.membership.renewal_failed) {
              await this.retryPaymentWithCurrentPaymentMethod();
            }
            await this.getMembership();
          });

          this.reepayWindow.addEventHandler(window.Reepay.Event.Error, () => {
          });
          this.reepayWindow.addEventHandler(window.Reepay.Event.Cancel, () => {
          });
          this.reepayWindow.addEventHandler(window.Reepay.Event.Close, async () => {
            await this.$store.dispatch('updateUser');
          });
          break;
        }

        case 'stripe': {
          this.loadingText = this.$t('membership.OneMoment');
          this.loading = true;
          const membershipId = this.$route.params.id;
          const success_url = `${document.location.origin}/frontend/index.html#/stripe/membership-update-payment-method-success/${membershipId}`;
          const setupSession = await YogoApi.post(
              '/payments/stripe/create-checkout-setup-session-for-membership',
              {
                membership: membershipId,
                success_url: success_url,
                cancel_url: document.location.href,
              });

          document.location = setupSession.url;
          break;
        }
      }

    },

    async retryPaymentWithCurrentPaymentMethod() {
      this.loading = true;
      const response = await YogoApi.post('/memberships/' + this.$route.params.id + '/retry-failed-subscription-payment');
      if (response === 'E_MEMBERSHIP_DOES_NOT_NEED_PAYMENT') {
        // Local data might be outdated. Reload.
        alert(this.$t('membership.upToDate'));
        this.getMembership();
        return;
      }
      if (response === 'E_PAYMENT_FAILED') {
        this.loading = false;
        alert(this.$t('global.paymentDeclined'));
        this.getMembership();
        return;
      }
      alert(this.$t('global.paymentSuccess'));
      this.getMembership();
    },
    async cancelUpcomingMembershipPause() {
      if (!confirm(this.$t('membership.WouldYouLikeToCancelTheUpcomingMembershipPause?'))) {
        return;
      }
      this.loading = true;
      await YogoApi.delete(`/membership-pauses/${this.membership.upcoming_membership_pause.id}`);
      this.getMembership();
      this.snackbarText = this.t('membership.MembershipPauseCancelled');
      this.showSnackbar = true;
    },
    getReceiptLink(order) {
      return this.apiRoot + '/orders/' + order.id + '/pdf-receipt?receiptToken=' + order.receipt_token;
    },
    avatar() {
      return avatar;
    },
  },
  watch: {
    stateReady(newReadyState) {
      if (newReadyState) this.getMemberships();
    },
  },
};
</script>

<style>


</style>
