<template>
  <md-dialog-alert
      :md-active.sync="showAlert"
      :md-content="$t('dialog.terms.YouMustAcceptThePrivacyPolicy')"
      :md-confirm-text="$t('dialog.Ok')"/>
</template>
<script>

export default {
  name: 'ForgotToAcceptPrivacyPolicyAlert',
  data() {
    return {
      showAlert: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show() {
      this.showAlert = this.show;
    },
    showAlert: {
      handler: function (val) {
        this.$emit('update:show', val);
      },
      immediate: true,
    },
  },
};
</script>
